import React, { useState, useEffect } from 'react';
import api from '../../api';    
import * as apiUrls from '../../apiUrls';
import './Domain.css';
import { FaSearch, FaTrash } from 'react-icons/fa'; // Import icons from react-icons library

const Domain = ({ templateId }) => {
    const [isAttachClicked, setIsAttachClicked] = useState(false);
    const [domainInput, setDomainInput] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const [isSearchPerformed, setIsSearchPerformed] = useState(false);
    const [templateDomains, setTemplateDomains] = useState([]);

    useEffect(() => {
        // Fetch domains based on templateId when the component mounts
        const fetchDomains = async () => {
            try {
                const response = await api(apiUrls.getDomainsForTemplateUrl(templateId));
                const data = await response;
                setTemplateDomains(data);
            } catch (error) {
                console.error('Error fetching domains for template:', error);
            }
        };

        fetchDomains();
    }, [templateId]);

    const handleAttachClick = async () => {
        try {
            const response = await api(apiUrls.getDomainsForTemplateUrl(templateId));
            const data = await response;
            setTemplateDomains(data);
            setIsAttachClicked(true);
        } catch (error) {
            console.error('Error fetching domains for template:', error);
        }
    };

    // Add a new function to handle domain deletion
    const handleDeleteClick = async (domainId) => {
        try {
            // Send a request to delete the domain
            await api(apiUrls.deleteDomainUrl(domainId), {
                method: 'DELETE',
            });

            // Fetch updated domains after deleting
            const response = await api(apiUrls.getDomainsForTemplateUrl(templateId));
            const data = await response;
            setTemplateDomains(data);
        } catch (error) {
            console.error('Error deleting domain:', error);
        }
    };

    const handleSearchClick = async () => {
        try {
            // Send a request to check if the domain exists
            const response = await api(apiUrls.checkDomainUrlInDoamin(domainInput));
            const data = await response;

            // Set the search result based on the response
            setSearchResult(data.exists);

            // Indicate that search has been performed
            setIsSearchPerformed(true);

        } catch (error) {
            console.error('Error checking domain:', error);
            // Handle error if needed
        }
    };

    const handleSaveClick = async () => {
        try {
            // Send a request to save the domain in both collections
            await api(apiUrls.saveDomainInFormOrderAndDomainUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ domainName: domainInput, templateId }),
            });

            // Fetch updated domains after saving
            const response = await api(apiUrls.getDomainsForTemplateUrl(templateId));
            const data = await response;
            setTemplateDomains(data);

            // You can add additional logic here after saving if needed

            // Reset the input and state
            setIsAttachClicked(false);
            setSearchResult(null);
            setIsSearchPerformed(false);
        } catch (error) {
            console.error('Error saving domain and form order:', error);
            // Handle error if needed
        }
    };

    return (
        <div className="WorkspaceDomain">
            {!isAttachClicked && (
                <>
                    {templateDomains.length > 0 ? (
                        <ul className="WorkspaceDomainul">
                            {templateDomains.map((domain) => (
                                <li key={domain._id} className="WorkspaceDomainli">
                                    <label className='dominaleble'>Domain Name :- </label>
                                    {domain.domainName}
                                    <button onClick={() => handleDeleteClick(domain._id)} className="WorkspaceDomaindelete">
                                        <FaTrash />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <button onClick={handleAttachClick} className="WorkspaceDomainattach">Attach Domain</button>
                    )}
                </>
            )}

            {isAttachClicked && (
                <div className='WorkspaceDomaindivii'>
                     {/* Display search result message */}
                     {isSearchPerformed && searchResult !== null && (
                        <p className={`WorkspaceDomainmsg ${searchResult ? 'message-error' : 'message-success'}`}>
                            {searchResult ? 'Domain already exists. Try Again.' : 'Domain is available. You can save it.'}
                        </p>
                    )}

                    <div  className='WorkspaceDomaindiviii'>
                        <input
                            type="text"
                            placeholder="Enter domain name"
                            value={domainInput}
                            onChange={(e) => setDomainInput(e.target.value)}
                            className="WorkspaceDomaininput"
                        />
                        <button onClick={handleSearchClick} className="WorkspaceDomainsearch">
                            <FaSearch />
                        </button>
                    </div>

                    {/* Conditionally display Save button based on search result and search performed */}
                    {isSearchPerformed && !searchResult && domainInput.trim() !== '' && (
                        <button onClick={handleSaveClick} className="WorkspaceDomainsave">
                            Save
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default Domain;
