import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";
import api from "../../api";
import * as apiUrls from "../../apiUrls";
import "./Blog CSS/LikeComment.css";

const LikeButton = ({ blogId }) => {
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [likedUsers, setLikedUsers] = useState([]);
  const [likedUsersNames, setLikedUsersNames] = useState([]);
  const [showLikedUsers, setShowLikedUsers] = useState(false);

  const history = useHistory();

  //console.log("blogId in like", blogId);

  useEffect(() => {
    // Fetch likes for the blog and update state
    const fetchLikes = async () => {
      try {
        const response = await api(apiUrls.getLikes(blogId));
        if (response) {
          const likesData = await response;

          setLikes(likesData.likes.length);
          setLikedUsers(likesData.likes.map((like) => like.userId));
          // Check if the current user has already liked the blog
          // const userLiked = likesData.likes.some(
          //   (like) => like.userId === userId
          // );
          // setIsLiked(userLiked);

          // Check if the user already liked the blog and update isLiked state accordingly
          if (likesData.alreadyLiked !== undefined) {
            setIsLiked(likesData.alreadyLiked);
          }
        }
      } catch (error) {
        console.error("Error fetching likes:", error);
      }
    };

    fetchLikes();
  }, [blogId, showLikedUsers]);

  //console.log("isLiked", isLiked);

  useEffect(() => {
    if (showLikedUsers) {
      const fetchLikedUsersNames = async () => {
        try {
          const userNamesResponse = await Promise.all(
            likedUsers.map(async (likedUserId) => {
              const response = await api(apiUrls.getUser(likedUserId));
              if (response) {
                const data = await response;
                return { id: likedUserId, name: data.userName };
              }
              return null;
            })
          );

          setLikedUsersNames(userNamesResponse.filter(Boolean));
        } catch (error) {
          console.error("Error fetching liked users names:", error);
        }
      };

      fetchLikedUsersNames();
    }
  }, [likedUsers, showLikedUsers]);

  const handleLike = async () => {
    try {
      const token = localStorage.getItem("token"); // Fetch token from localStorage
      if (!token) {
        console.error("User is not authenticated.");
        return;
      }

      // Send request to like/unlike with token in headers
      const response = await api(apiUrls.likeOrUnlikeBlog(blogId), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass token in headers
        },
        // body: JSON.stringify({
        //   userId: userId,
        // }),
      });

      if (response) {
        const responseData = await response;
        if (responseData.message === "Blog liked successfully") {
          setIsLiked(true);
          setLikes((prevLikes) => prevLikes + 1);
        } else if (responseData.message === "Blog unliked successfully") {
          setIsLiked(false);
          setLikes((prevLikes) => Math.max(prevLikes - 1, 0));
        }
        // Check if the user already liked the blog and update isLiked state accordingly
        if (responseData.alreadyLiked !== undefined) {
          setIsLiked(responseData.alreadyLiked);
        }
      } else {
        console.error(
          "Error liking/unliking blog:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error liking/unliking blog:", error);
    }
  };

  // const handleLike = async () => {
  //   try {
  //     if (isLiked) {
  //       // If already liked, send a request to unlike
  //       const response = await api(apiUrls.unlikeBlog(blogId), {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           userId,
  //         }),
  //       });

  //       if (response) {
  //         setIsLiked(false);
  //         setLikes((prevLikes) => Math.max(prevLikes - 1, 0)); // Decrease like count
  //         setLikedUsers((prevUsers) =>
  //           prevUsers.filter((user) => user !== userId)
  //         );
  //       } else {
  //         console.error(
  //           "Error unliking blog:",
  //           response.status,
  //           response.statusText
  //         );
  //       }
  //     } else {
  //       // If not liked, send a request to like
  //       const response = await api(apiUrls.likeBlog(blogId), {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           userId,
  //         }),
  //       });

  //       if (response) {
  //         setIsLiked(true);
  //         setLikes((prevLikes) => prevLikes + 1); // Increase like count
  //         setLikedUsers((prevUsers) => [...prevUsers, userId]);
  //       } else {
  //         console.error(
  //           "Error liking blog:",
  //           response.status,
  //           response.statusText
  //         );
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error liking/unliking blog:", error);
  //   }
  // };

  const handleLikedUsersClick = () => {
    setShowLikedUsers(true);
  };

  const closeModal = () => {
    setShowLikedUsers(false);
    setLikedUsersNames([]);
  };

  // console.log("userLikeId -> " + userId);

  const handleBlogAuthorClick = (id) => {
    let profileId = id;
    history.push(`/userProfile/${profileId}`);
  };

  return (
    <div className="LikeButton">
      <button type="button" onClick={handleLike}>
        <span
          role="img"
          aria-label="thumb icon"
          className={isLiked ? "liked" : "notLiked"}
        >
          {" "}
          {isLiked ? (
            <FontAwesomeIcon icon={faThumbsUp} className="liked" />
          ) : (
            <FontAwesomeIcon icon={faThumbsUp} className="notLiked" />
          )}
        </span>
      </button>

      <button
        type="button"
        className="likeperson"
        onClick={handleLikedUsersClick}
        tabIndex="0"
        onKeyDown={handleLikedUsersClick}
      >
        <span className="personLikeThis">&nbsp; Like ({likes})</span>
      </button>

      {showLikedUsers && (
        <Modal isOpen={showLikedUsers} onClose={closeModal}>
          <h2 className="modalHeader">
            {likedUsersNames.length > 0 ? (
              <>
                Liked by&nbsp;
                {likes}&nbsp; Person
              </>
            ) : (
              "No one has liked this blog yet."
            )}
          </h2>
          <div className="modalLikers curserPointer">
            {likedUsersNames.map(({ id, name }) => (
              <p key={id} onClick={() => handleBlogAuthorClick(id)}>{name}</p>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

LikeButton.propTypes = {
  blogId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default LikeButton;
