import React, { useState, useEffect, lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { useDrop, useDrag } from "react-dnd";
import api from "../../api";
import * as apiUrls from "../../apiUrls";
import PortfoliosNavbar from "../Products/PortfolioNavbar";
import "./form-container.css";

const DraggableForm = ({ formName, onFormClick, isSelected }) => {
  const [, drag] = useDrag({
    type: "FORM_ITEM",
    item: { formName },
  });

  return (
    <div
      ref={drag}
      className={`form-link ${isSelected ? "active" : ""}`}
      onClick={() => onFormClick(formName)}
    >
      {formName}
    </div>
  );
};

const FormContainer = () => {
  const location = useLocation();
  const templateId = location.pathname.split("/").pop();

  const [selectedForm, setSelectedForm] = useState("Home");
  const [template, setTemplate] = useState(null);
  const [formData, setFormData] = useState({
    NavBar: {},
    Home: {},
    Social: {},
    About: {},
    Skills: {},
    Education: {},
    Experiences: {},
    Projects: {},
  });

  const [allFormData, setAllFormData] = useState({});
  const [componentIdMapping, setComponentIdMapping] = useState({});

  const [, drop] = useDrop({
    accept: "FORM_ITEM",
    drop: (item) => handleFormItemDrop(item),
  });

  const handleFormItemDrop = async (item) => {
    const forms = template ? template.component : [];
    const draggedFormIndex = forms.indexOf(item.formName);
    const targetFormIndex = forms.indexOf(selectedForm);
    const updatedForms = [...forms];
    updatedForms.splice(draggedFormIndex, 1);
    updatedForms.splice(targetFormIndex, 0, item.formName);

    // Update the form order in the database
    try {
      const response = await api(apiUrls.updateFormOrderUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          formOrder: updatedForms,
          templateId,
          templateName: template.name,
        }),
      });
      if (!response.ok) {
        console.error("Failed to update form order:", response.statusText);
        // Handle the error as needed
      }

      const result = await response;
      //console.log('Form order updated:', result);

      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        component: updatedForms,
      }));
    } catch (error) {
      console.error("Error updating form order:", error.message);
      // Handle other errors
    }
  };

  useEffect(() => {
    const fetchFormOrderNames = async () => {
      try {
        const response = await api(apiUrls.getFormOrderNamesUrl(templateId));
        //console.log('form ' ,response);
        // if (!response.ok) {
        //     console.error('Failed to fetch form order names:', response.statusText);
        //     // Handle the error as needed
        //     return;
        // }

        const formOrderData = await response;

        // Set the template component and selected form
        setTemplate((prevTemplate) => ({
          ...prevTemplate,
          component: formOrderData.order,
          componentsId: formOrderData.componentsId,
        }));
        setSelectedForm(formOrderData.order[0]);

        // Create a mapping of form names to component IDs
        const componentIdMapping = {};
        formOrderData.componentsId.forEach((component) => {
          if (component.name && component.id) {
            componentIdMapping[component.name] = component.id;
          }
        });

        // Set the componentIdMapping state
        setComponentIdMapping(componentIdMapping);
        //console.log(componentIdMapping);
      } catch (error) {
        console.error("Error fetching form order names:", error.message);
        // Handle other errors
      }
    };

    fetchFormOrderNames();
  }, [templateId]);

  const handleFormClick = (formName) => {
    setSelectedForm(formName);
  };

  const handleBackClick = () => {
    const forms = template ? template.component : [];
    const currentIndex = forms.indexOf(selectedForm);
    const prevIndex = (currentIndex - 1 + forms.length) % forms.length;
    setSelectedForm(forms[prevIndex]);
  };

  const handleNextClick = async () => {
    try {
      const collectedData = formData[selectedForm];
      const componentId = componentIdMapping[selectedForm];

      setAllFormData((prevAllFormData) => ({
        ...prevAllFormData,
        [selectedForm]: collectedData,
      }));

      const response = await api(apiUrls.saveFormDataUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          formName: selectedForm,
          formData: collectedData,
          templateId,
          componentId,
        }),
      });

      // if (!response) {
      //     throw new Error(`Server responded with ${response.status} - ${response.statusText}`);
      // }

      const result = await response;

      if (result.success) {
        //console.log('Data saved to the database successfully:', result.message);

        const forms = template ? template.component : [];
        const currentIndex = forms.indexOf(selectedForm);
        const nextIndex = (currentIndex + 1) % forms.length;

        if (nextIndex === 0) {
          // If the next form is the first form, navigate to /workspace
          window.open("/workspace", "_parent");
        } else {
          // Update the selectedForm to navigate to the next form
          setSelectedForm(forms[nextIndex]);
        }
      } else {
        console.error("Failed to save data to the database:", result.error);
        // Handle specific error cases if needed
      }
    } catch (error) {
      console.error("Error while handling next click:", error.message);
      // Handle other errors
    }
  };

  const onNext = (formName, data) => {
    setFormData((prevData) => ({ ...prevData, [formName]: data }));
  };

  const renderForm = () => {
    const componentId = componentIdMapping[selectedForm];

    if (!componentId) {
      console.error(`Component ID for form '${selectedForm}' is not defined.`);
      return null;
    }

    const ComponentToRender = lazy(() => import(`./${componentId}`));

    return (
      <Suspense fallback={<div>Loading...</div>}>
        <ComponentToRender
          onNext={onNext}
          templateId={templateId}
          formOrder={template}
        />
      </Suspense>
    );
  };

  // const renderForm = () => {
  //     switch (selectedForm) {
  //         case 'Home':
  //             return <HomeForm onNext={onNext} templateId={templateId} />;
  //         case 'Social':
  //             return <SocialForm onNext={onNext} templateId={templateId} />;
  //         case 'About':
  //             return <AboutForm onNext={onNext} templateId={templateId} />;
  //         case 'Education':
  //             return <EducationForm onNext={onNext} templateId={templateId} />;
  //         case 'Experiences':
  //             return <ExperiencesForm onNext={onNext} templateId={templateId} />;
  //         case 'Projects':
  //             return <ProjectsForm onNext={onNext} templateId={templateId} />;
  //         case 'Skills':
  //             return <SkillsForm onNext={onNext} templateId={templateId} />;
  //         default:
  //             return null;
  //     }
  // };

  return (
    <div>
      <PortfoliosNavbar />
      <div className="form-container">
        <div className="form-sidebar" ref={drop}>
          {template &&
            template.component.map((formName) => (
              <DraggableForm
                key={formName}
                formName={formName}
                onFormClick={handleFormClick}
                isSelected={formName === selectedForm}
              />
            ))}
        </div>
        <div className="form-main-content">
          {selectedForm && (
            <div>
              <h2>{`${selectedForm} Form`}</h2>
              {renderForm()}
            </div>
          )}
          <div className="form-navigation">
            <button
              onClick={handleBackClick}
              disabled={
                template && template.component.indexOf(selectedForm) === 0
              }
            >
              Back
            </button>

            <button onClick={handleNextClick}>
              {template &&
              selectedForm === template.component[template.component.length - 1]
                ? "Submit"
                : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormContainer;
