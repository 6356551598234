import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { submitContactForm } from "../SearchApi";

const ContactForm = () => {
  const navigate = useHistory();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await submitContactForm(formData);
  
      if (response.message) {
        // Manually scroll to the top of the page
        window.scrollTo({
          top: 0,
          behavior: "smooth", // You can use "auto" or "smooth" for scrolling behavior
        });
  
        // Navigate to the home page
        navigate.push("/");
  
        // Clear form data
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
      } else {
        console.error("Failed to submit form.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  

  return (
    <div className="ContactUs">
      <div className="contact-form">
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="form-groupinput-container">
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder=""
              />
              <label htmlFor="name"> Name</label>
            </div>
          </div>

          <div className="form-group">
            <div className="form-groupinput-container">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder=""
              />
              <label htmlFor="email"> Email</label>
            </div>
          </div>

          <div className="form-group">
            <div className="form-groupinput-container">
              <input
                type="phoneNumber"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                placeholder=""
              />
              <label htmlFor="phone"> Phone Number </label>
            </div>
          </div>

          <div className="form-group">
            <div className="form-groupinput-container">
              <textarea
                id="message"
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder=""
              ></textarea>
              <label htmlFor="message"> Message</label>
            </div>
          </div>

          <button type="submit" className="contact-formBtn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
