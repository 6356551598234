import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeMessage } from "../Components/Redux/actions";
import "./MessagePopup.css";

const MessagePopup = () => {
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const messages = useSelector((state) => state.messages.messages) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (messages.length > 0) {
      const newMessages = messages.filter(
        (message) => !displayedMessages.includes(message)
      );
      setDisplayedMessages((prevMessages) => [...prevMessages, ...newMessages]);
    }
  }, [messages]);

  useEffect(() => {
    const timers = displayedMessages.map((message) => {
      return setTimeout(() => {
        handleRemoveMessage(message);
      }, 5000);
    });

    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, [displayedMessages]);

  const handleRemoveMessage = (message) => {
    dispatch(removeMessage(message));
    setDisplayedMessages((prevMessages) =>
      prevMessages.filter((msg) => msg !== message)
    );
  };

  if (displayedMessages.length === 0) {
    return null;
  }

  return (
    <div className="MessagePopup">
      {displayedMessages.map((message, index) => (
        <div className="message" key={index}>
          <div className="messageContent">
            <span>{message}</span>
            <button onClick={() => handleRemoveMessage(message)}>✕</button>
          </div>
          <div className="progressBarContainer">
            <div
              className="progressBar"
              style={{ animationDuration: "5s" }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MessagePopup;
