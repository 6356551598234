import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./sidebar.css";
import defaultPhotoUrl from "../../../assets/images/User.png";
import { profilePicBytoken } from "../../SearchApi";

const Sidebar = () => {
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [photo, setPhoto] = useState(false);

  const handleSidebarToggle = () => {
    const sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("close");
  };

  const handleSubMenuToggle = (e) => {
    const arrowParent = e.target.parentElement.parentElement;
    arrowParent.classList.toggle("showMenu");
  };

  const portfolioMappings = {
    "Restaurant WebSites": "Portfolio3",
    "Personal Portfolio": "Portfolio1",
    "Retail WebSites": "Portfolio2",
  };

  const handleCategoryClick = (category) => {
    const identifier = portfolioMappings[category];

    if (identifier) {
      history.push(`/products/${identifier}`);
    } else {
      console.error(`Identifier not found for category: ${category}`);
    }
  };

  document.addEventListener("click", (e) => {
    const sidebar = document.querySelector(".sidebar");

    // Check if sidebar is found in the document
    if (sidebar) {
      if (
        !sidebar.contains(e.target) &&
        !e.target.classList.contains("bx-menu")
      ) {
        sidebar.classList.add("close");
        document.body.classList.remove("overlay");
      }
    }
  });

  useEffect(() => {
    // Check if the user is logged in
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("name");

    setIsLoggedIn(!token);
    setUserName(name || "");
  }, []);

  //console.log("left ", { userName });

  const handleLogout = () => {
    // Clear localStorage and update login status
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("eamil");
    setIsLoggedIn(false);
    // Redirect to the logout page if needed
    history.push("/logout");
  };

  useEffect(() => {
    profilePicBytoken(setPhoto);
  }, []);

  return (
    <>
      <div className="sidebar close">
        <div className="sidenavlinktextdecor">
          <Link to="/">
            <div className="logo-details">
              <img src="/images/logo.PNG"></img>
              <span className="logo_name">OwnsIt</span>
            </div>
          </Link>
        </div>
        <ul className="nav-links">
          <li>
            <a href="/">
              <i className="bx bx-home-heart"></i>
              <span className="link_name">Home</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="/">
                  Home
                </a>
              </li>
            </ul>
          </li>

          {!isLoggedIn && (
            <li>
              <a href="/createBlog">
                <i class="bx bxs-file-plus"></i>
                <span className="link_name">Create Blog</span>
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/createBlog">
                    Create Blog
                  </a>
                </li>
              </ul>
            </li>
          )}

          <li>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Products</span>
              </a>
              <i
                className="bx bxs-chevron-down arrow"
                onClick={handleSubMenuToggle}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Products
                </a>
              </li>
              {[
                "Personal Portfolio",
                "Retail WebSites",
                "Restaurant WebSites",
              ].map((category) => (
                <a key={category} onClick={() => handleCategoryClick(category)}>
                  {category}
                </a>
              ))}
            </ul>
          </li>
          {/* <li>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-compass"></i>
                <span className="link_name">Resources</span>
              </a>
              <i
                className="bx bxs-chevron-down arrow"
                onClick={handleSubMenuToggle}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Resources
                </a>
              </li>
              <li>
                <a href="#">Resources 1</a>
              </li>
              <li>
                <a href="#">Resources 2</a>
              </li>
              <li>
                <a href="#">Resources 3</a>
              </li>
            </ul>
          </li> */}
          {/* <li>
            <a href="/blogs">
              <i className="bx bx-book-alt"></i>
              <span className="link_name">Blogs</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="#">
                  Blogs
                </a>
              </li>
            </ul>
          </li> */}
          {/* <li>
            <a href="#">
              <i className="bx bx-cog"></i>
              <span className="link_name">Setting</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="#">
                  Setting
                </a>
              </li>
            </ul>
          </li> */}
          {isLoggedIn ? (
            <li>
              <a href="/login">
                <i className="bx bx-user-circle"></i>
                <span className="link_name">Login</span>
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/login">
                    Login
                  </a>
                </li>
              </ul>
            </li>
          ) : (
            <li>
              <a href="/" onClick={handleLogout}>
                <i className="bx bx-log-out"></i>
                <span className="link_name">Logout</span>
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/" onClick={handleLogout}>
                    Logout
                  </a>
                </li>
              </ul>
              <div className="profile-details">
                <div className="profile-content">
                  <img src={photo || defaultPhotoUrl} alt="User" className="BlogProfilePhoto" />
                </div>
                <div className="name-job">
                  <div className="profile_name">{userName}</div>
                  {/* <div className="job">User Details</div> */}
                </div>
                <a href="/" onClick={handleLogout}>
                  <i className="bx bx-log-out"></i>
                </a>
              </div>
            </li>
          )}
        </ul>
      </div>
      <section className="home-section">
        <div className="home-content">
          <i className="bx bx-menu" onClick={handleSidebarToggle}></i>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
