import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Components/Redux/store";
import LandingPage from "./Components/LandingPage";
import SearchResultsPage from "./Components/Searching/MainSearchCombinePage/SearchResultsPage";
import BlogLandingPage from "./Components/Blog/BlogLandingPage";
import PortfolioList from "./Components/Products/PortfolioList/PortfolioList";
import FormContainer from "./Components/Sections/FormContainer";
import PortfolioDetails from "./Components/Products/PortfolioDetails";
import Workspace from "./Components/Workspace/Workspace";
import Customise from "./Components/Customise/Customise";
import Routess from "./sharedcopy/Routess";
import LoggedInRoutes from "./sharedcopy/LoggedInRoutes";
import Navbar from "./Components/Navbar/Navbar";
import ContactForm from "./Components/Pages/ContactForm";
import MessagePopup from "./MessagePopup/MessagePopup";
import DashboardMain from "./Dashboard/DashboardMain";
import BlogDetails from "./Components/Blog/BlogDetails";
import CreateBlog from "./Components/Blog/CreateBlog";
import "./App.css";
import ProfilePage from "./UserProfile/ProfilePage";

const App = () => {
  const isAuthenticated = localStorage.getItem("token");

  // Get redirect URL from cookies
  const getRedirectUrlFromCookies = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith("redirectUrl=")) {
        return cookie.substring("redirectUrl=".length);
      }
    }
    return null;
  };

  // Remove token from localStorage and redirect to login page
  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  // Get redirect URL from cookies
  const redirectUrl = getRedirectUrlFromCookies();

  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <Route exact path="/search-results">
          <SearchResultsPage />
        </Route>
        <Route exact path="/">
          <LandingPage />
        </Route>
        
        <div className="createBlogMain">
          <Route exact path="/createBlog">
            <CreateBlog />
          </Route>
        </div>

        <Route exact path="/blogs">
          <BlogLandingPage />
        </Route>
        <Route exact path="/blogs/:blogId">
          <BlogDetails />
        </Route>

        <Route exact path="/contactUs">
          <ContactForm />
        </Route>
        <Switch>
          <Route path="/products/:portfolioId">
            <PortfolioDetails />
          </Route>
          <Route path="/products/:category">
            <PortfolioDetails />
          </Route>
          <Route path="/products">
            <PortfolioList />
          </Route>
        </Switch>
        <Route path="/workspace/template/:templateId">
          <FormContainer />
        </Route>
        <Route path="/workspace">
          <Workspace />
        </Route>
        <Route path="/customise">
          <Customise />
        </Route>
        <Route path="/userProfile/:profileId">
          <ProfilePage />
        </Route>
        {/* <Route path="/dashboard">
          {isAuthenticated ? <DashboardMain /> : <Redirect to="/login" />}
        </Route> */}
        <Route path="/login">
          {isAuthenticated ? (
            redirectUrl ? (
              // Remove token and redirect to redirect URL
              handleLogout()
            ) : (
              // Token exists but no redirect URL, redirect to dashboard
              // <Redirect to="/dashboard" />
               <Redirect to="/" />
            )
          ) : (
            // User not authenticated, show login page
            <Redirect to="/login" />
          )}
        </Route>
        <MessagePopup />
        <Route>
          <input style={{ opacity: 0, position: "absolute" }} />
          <input type="password" style={{ opacity: 0, position: "absolute" }} />
          {!localStorage.getItem("token") ? <Routess /> : <LoggedInRoutes />}
        </Route>
      </Router>
    </Provider>
  );
};

export default App;
