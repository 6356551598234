import React, { useState } from "react";
import "../../Css/SearchNav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBoxOpen,
  faBookOpen,
  faBlog,
  faUser,
  faBars,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const SearchNav = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    const handleMenuClick = () => {
      setIsMenuOpen(!isMenuOpen);
    };

  return (
    <div className="MainSerachNavCont">
     <nav className={`SearcNavbar navbar-vertical ${isMenuOpen ? "open" : ""}`}>
        <div className="SearcNavbar-brand">
            {!isMenuOpen && <a className="Searchlogo" href="/" ><img src="/images/logo.PNG"></img></a>}
            {isMenuOpen && (
            <div className="Searchlogo-and-name">
              <a className="Searchlogo"><img src="/images/logo.PNG"></img></a>
              <span className="name">Your Name</span>
            </div>
          )}
        </div>
        <div className="SearcNavbar-men">
            <ul className="SearcNavbar-nav">
            <li className="SearcNav-item">
                <a href="/" className="SearcNav-link">
                <FontAwesomeIcon icon={faHome} />
                </a>
            </li>
            <li className="SearcNav-item">
                <a href="#" className="SearcNav-link">
                <FontAwesomeIcon icon={faBoxOpen} />
                </a>
            </li>
            <li className="SearcNav-item">
                <a href="#" className="SearcNav-link">
                <FontAwesomeIcon icon={faBookOpen} />
                </a>
            </li>
            <li className="SearcNav-item">
                <a href="/blogs" className="SearcNav-link">
                    <FontAwesomeIcon icon={faBlog} />
                </a>
            </li>
            <li className="SearcNav-item">
                <a href="/login" className="SearcNav-link">
                <FontAwesomeIcon icon={faUser} />
                </a>
            </li>
            </ul>
        </div>
        <div className="SearcNavbar-toggler" onClick={handleMenuClick}>
          <span className="SearcNavbar-toggler-icon">
            <FontAwesomeIcon icon={faBars} />
          </span>
        </div>
      </nav>

      {!isMenuOpen && (
        <div className="SearcNavbar-menu animate__animated animate__fadeInUp">
          <div className="SearcNavbar-menu-inner">
            <ul className="SearcNavbar-menu-list">
              <li className="SearcNavbar-menu-item">
                <a href="#" className="SearcNavbar-menu-link">
                  <FontAwesomeIcon icon={faBoxOpen} />
                  Products
                </a>
              </li>
              <li className="SearcNavbar-menu-item">
                <a href="#" className="SearcNavbar-menu-link">
                  <FontAwesomeIcon icon={faBookOpen} />
                  Resources
                </a>
              </li>
              <li className="SearcNavbar-menu-item">
                <a href="#" className="SearcNavbar-menu-link">
                  <FontAwesomeIcon icon={faUser} />
                  Login
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchNav;
