// DynamicComponentViewer.js
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import DynamicSelecte from "./DynamicSelecte";
import api from "../../api";
import * as apiUrls from "../../apiUrls";

const DynamicComponentViewer = ({ formOrder, setFormOrder }) => {
  const [sectionNames, setSectionNames] = useState([]);
  const [selectedSection, setSelectedSection] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [alreadySelectedComponentIds, setAlreadySelectedComponentIds] =
    useState([]);
  const [arrowRotation, setArrowRotation] = useState({});

  useEffect(() => {
    api("http://localhost:5001/getSectionNames")
      .then((response) => response)
      .then((data) => setSectionNames(data))
      .catch((error) => console.error("Error fetching section names:", error));
  }, []);

  const handleSectionClick = async (sectionName) => {
    document
      .querySelectorAll(".DynamicComponentContainerli")
      .forEach((item) => {
        item.classList.remove("selected");
      });

    setArrowRotation((prevRotation) => ({
      ...prevRotation,
      [sectionName]: !prevRotation[sectionName],
    }));

    if (selectedSection.sectionName === sectionName) {
      setSelectedSection((prevState) => ({
        ...prevState,
        collapsed: !prevState.collapsed,
      }));
    } else {
      try {
        const response = await api(
          `http://localhost:5001/getSectionDetails/${sectionName}`
        );
        const data = await response;
        //console.log("section ", data);
        setSelectedSection({ ...data, collapsed: false });

        // Check if there are any matches in formOrder.componentToSubComponent
        const matchingComponents = data.components.filter((component) =>
          formOrder?.componentToSubComponent.some(
            (subComponent) => subComponent.id === component.id
          )
        );

        if (matchingComponents.length > 0) {
          const updatedSelectedItems = matchingComponents.map((component) => ({
            id: component.id,
            name: component.name,
            details: component.details,
            sectionName: sectionName,
          }));

          const newAlreadySelectedComponentIds = updatedSelectedItems.map(
            (item) => item.id
          );
          setAlreadySelectedComponentIds((prevIds) => [
            ...prevIds,
            ...newAlreadySelectedComponentIds,
          ]);
          setSelectedItems(updatedSelectedItems);
        }
      } catch (error) {
        console.error("Error fetching section details:", error);
      }

      const selectedElement = document.querySelector(
        `.DynamicComponentContainerli[data-section="${sectionName}"]`
      );
      if (selectedElement) {
        selectedElement.classList.add("selected");
      }
    }
  };

  const getButtonText = (component) => {
    const isSelected = alreadySelectedComponentIds.includes(component.id);

    if (isSelected) {
      return "Selected";
    } else {
      return "Select";
    }
  };

  useEffect(() => {
    const fetchDataForNames = async () => {
      if (formOrder?.componentToSubComponent) {
        for (const subComponent of formOrder.componentToSubComponent) {
          try {
            const response = await api(
              `http://localhost:5001/getSectionDetails/${subComponent.name}`
            );
            const data = await response;
            //console.log("section ", data);

            // Filter components based on matching id
            const matchingComponents = Object.values(
              data.components || {}
            ).filter((component) => component.id === subComponent.id);

            if (matchingComponents.length > 0) {
              const updatedSelectedItems = matchingComponents.map(
                (component) => ({
                  id: component.id,
                  name: component.name,
                  details: component.details,
                  sectionName: subComponent.name,
                })
              );

              const newAlreadySelectedComponentIds = updatedSelectedItems.map(
                (item) => item.id
              );
              setAlreadySelectedComponentIds((prevIds) => [
                ...prevIds,
                ...newAlreadySelectedComponentIds,
              ]);
              setSelectedItems((prevItems) => [
                ...prevItems,
                ...updatedSelectedItems,
              ]);
            }
          } catch (error) {
            console.error("Error fetching section details:", error);
          }
        }
      }
    };

    fetchDataForNames();
  }, [formOrder?.componentToSubComponent]);

  const handleDemoClick = (component) => {
    if (!alreadySelectedComponentIds.includes(component.id)) {
      const demoLink = component.demo;

      // Open the demo link in a new window
      window.open(demoLink, "_blank");
    }
  };

  const handleSelectButtonClick = (component, sectionName) => {
    if (!alreadySelectedComponentIds.includes(component.id)) {
      const isComponentInFormOrder = formOrder?.componentToSubComponent.some(
        (comp) => comp.id === component.id
      );

      const updatedSelectedItems = [
        ...selectedItems,
        {
          id: component.id,
          name: component.name,
          details: component.details,
          sectionName: sectionName,
        },
      ];

      setSelectedItems(updatedSelectedItems);
      setAlreadySelectedComponentIds((prevIds) => [...prevIds, component.id]);
    }
  };

  const handleDeleteSelectedItem = (itemToDelete) => {
    setAlreadySelectedComponentIds((prevIds) =>
      prevIds.filter((id) => id !== itemToDelete.id)
    );
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => item.id !== itemToDelete.id)
    );
  };

  useEffect(
    (sectionName) => {
      const updatedSelectedItems = Object.values(
        selectedSection?.components || {}
      )
        .filter(
          (component) =>
            component.selected &&
            !alreadySelectedComponentIds.includes(component.id)
        )
        .map((component) => ({
          id: component.id,
          name: component.name,
          details: component.details,
          sectionName: sectionName,
        }));

      const newSelectedComponentIds = updatedSelectedItems.map(
        (item) => item.id
      );
      setAlreadySelectedComponentIds((prevIds) => [
        ...prevIds,
        ...newSelectedComponentIds,
      ]);

      const mergedSelectedItems = [...selectedItems, ...updatedSelectedItems];
      const uniqueSelectedItems = Array.from(
        new Set(mergedSelectedItems.map((item) => item.id))
      ).map((id) => mergedSelectedItems.find((item) => item.id === id));

      setSelectedItems(uniqueSelectedItems);
      //console.log('Updated selected items:', uniqueSelectedItems);
    },
    [selectedSection]
  );

  const handleSearch = async () => {
    try {
      const response = await api(
        `http://localhost:5001/getDynamicComponent?searchTerm=${searchTerm}`
      );
      const data = await response;
      //console.log('Fetched data:', data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredSectionNames = sectionNames.filter((sectionName) =>
    sectionName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="DynamicComponentContainerMain">
      <div className="DynamicComponentContainer">
        <div className="DynamicComponentSearchContainer">
          <input
            type="text"
            placeholder="Search..."
            onChange={handleInputChange}
            value={searchTerm}
            className="DynamicComponentContainerSearch"
          />
        </div>
        <div>
          {filteredSectionNames.length > 0 ? (
            <ul className="DynamicComponentContainerul">
              <div className="DynamicComponentContainerul">
                {filteredSectionNames.map((sectionName) => (
                  <li
                    key={sectionName}
                    data-section={sectionName}
                    className="DynamicComponentContainerli"
                  >
                    <div
                      onClick={() => handleSectionClick(sectionName)}
                      className={`DynamicComponentContainerSection ${
                        selectedSection.sectionName === sectionName
                          ? "selected"
                          : ""
                      }`}
                    >
                      <div className="DynamicComponentContainerSectionName">
                        {sectionName}
                        <div className="RightTickIcon">
                          {formOrder?.componentToSubComponent.find(
                            (comp) => comp.name === sectionName
                          ) && "✔"}
                        </div>
                      </div>
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className={`ArrowIcon ${
                          arrowRotation[sectionName] ? "rotated" : ""
                        }`}
                      />
                    </div>
                    <div>
                      {selectedSection.sectionName === sectionName &&
                        !selectedSection.collapsed && (
                          <div className="DynamicComponentContainerdivi">
                            <ul className="DynamicComponentContainerulII">
                              {Object.values(
                                selectedSection.components || {}
                              ).map((component) => (
                                <li
                                  key={component.id}
                                  className={`DynamicComponentContainerliII ${
                                    component.selected ? "selected" : ""
                                  }`}
                                >
                                  <h3 className="DynamicComponentContainername">
                                    {component.name}
                                  </h3>
                                  <p className="DynamicComponentContainerdetails">
                                    {component.details}
                                  </p>
                                  {component.tags && (
                                    <p className="DynamicComponentContainertags">
                                      Tags: {component.tags.join(", ")}
                                    </p>
                                  )}
                                  <div className="DynamicComponentContainerbtn">
                                    <button
                                      className="DynamicComponentContainerbtnDemo"
                                      onClick={() => handleDemoClick(component)}
                                    >
                                      Demo
                                    </button>
                                    <button
                                      className="DynamicComponentContainerbtnSelect"
                                      onClick={() =>
                                        handleSelectButtonClick(
                                          component,
                                          sectionName
                                        )
                                      }
                                      disabled={alreadySelectedComponentIds.includes(
                                        component.id
                                      )}
                                    >
                                      {getButtonText(component)}
                                    </button>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                    </div>
                  </li>
                ))}
              </div>
            </ul>
          ) : (
            <p>No matching section names...</p>
          )}
        </div>
      </div>
      <div className="DynamicSelectComponentDiv">
        <DynamicSelecte
          selectedItems={selectedItems}
          updateSelectedItems={setSelectedItems}
          handleDeleteItem={handleDeleteSelectedItem}
          sectionName={selectedSection.sectionName}
          formOrder={formOrder}
        />
      </div>
    </div>
  );
};

export default DynamicComponentViewer;
