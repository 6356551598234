import React from "react";
import "./Css/LandingPage.css";
import HomePage from "./Pages/HomePage";
import Page2 from "./Pages/Page2";
import Features from "./Pages/Features";
import Service from "./Pages/Service";
import ContactForm from "./Pages/ContactForm";
import Footer from "./Pages/Footer";
import DomainPage from "./Pages/DomainPage";
import DomainSlider from "./Pages/DomainSlider";

function LandingPage() {
  return (
    <div className="LandingPage">
      <div className="LandingP">
        {/* <div className="mainImage">
          <img src={mainGif} alt="main" />
        </div> */}
        <HomePage />
      </div>
      <div>
        <Page2 />
      </div>
      <div>
        <Features />
      </div>
      <div>
        <Service />
      </div>

      <div className="domainBackgorund">
      <DomainPage />
      <DomainSlider />
      </div>
      
      <div className="contactOverfolw">
        <ContactForm />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default LandingPage;
