// SearchResultsPage.js
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchResults from "../SearchResults";
import { useSelector } from "react-redux";
import { fetchBlogs, fetchUsers } from "../../SearchApi";

const SearchResultsPage = () => {
  const location = useLocation();
  const searchResults = useSelector((state) => state.searchResults);
  const searchTerm = searchResults.searchTerm;
  const results = location.state ? location.state.searchResults : [];
  const [currentView, setCurrentView] = useState("blogs");
  const [relatedUsers, setRelatedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);

  const switchToBlogs = async () => {
    setCurrentView("blogs");
    try {
      setLoading(true);
      const foundBlog = await fetchBlogs(searchTerm);
      //console.log('Found Blog:', foundBlog);
      setRelatedUsers(foundBlog);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const switchToPeople = async () => {
    setCurrentView("people");
    try {
      setLoading(true);
      const foundUsers = await fetchUsers(searchTerm);
      //console.log('Found Users:', foundUsers);
      setRelatedUsers(foundUsers);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    //console.log('Results updated:', results);
  }, [results]);

  return (
    <div>
      <SearchResults
        results={results}
        searchTerm={location.state?.searchTerm}
        switchToBlogs={switchToBlogs}
        switchToPeople={switchToPeople}
        currentView={currentView}
        loading={loading}
        relatedUsers={relatedUsers}
        onSelectBlog={setSelectedBlog}
      />
    </div>
  );
};

export default SearchResultsPage;
