// PortfolioList.js
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../Css/PortfolioList.css";
import PortfolioNavbar from "../PortfolioNavbar";
import PortfolioItem from "../PortfolioItem";
import AllTemplates from "../AllTemplates";
import portfoliosList from "./portfoliosList.json";
import AnimatedBackground from "./AnimatedBackground";
import FormContainer from "../../Sections/FormContainer";
import PortfolioDetails from "../PortfolioDetails";
import { Route, Switch } from "react-router-dom";
import Page2 from "../../Pages/Page2";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function PortfolioList() {
  const [allTemplates, setAllTemplates] = useState([]);
  const [showFormContainer, setShowFormContainer] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const fetchAllTemplates = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/templates`);
        const data = await response.json();

        setAllTemplates(data);
      } catch (error) {
        console.error("Error fetching all templates", error);
      }
    };

    fetchAllTemplates();
  }, []);

  const handlePortfolioClick = (portfolioId) => {
    // Redirect to the portfolio details page
    history.push(`/products/${portfolioId}`);
  };

  const handleTryClick = () => {
    history.push("/try-form");
  };

  return (
    <div className="MainPortfolio">
      <div className="PortfolioContainer">
        <Route path="/products/:portfolioId">
          {/* Render the PortfolioDetails component when the URL matches */}
          <PortfolioDetails />
        </Route>
        <Route path="/">
          {/* Render the PortfolioList component when the URL doesn't match any specific portfolio */}

          <div>
            <Page2 />
          </div>
          {/* <div className="portfolio-list">
              {portfoliosList.map((portfolio) => (
                <PortfolioItem
                  key={portfolio.id}
                  name={portfolio.name}
                  details={portfolio.details}
                  onClick={() => handlePortfolioClick(portfolio.id)}
                  onTryClick={handleTryClick}
                />
              ))}
            </div> */}

          {showFormContainer && <FormContainer />}

          {!showFormContainer && (
            <AllTemplates
              templates={allTemplates}
              setShowFormContainer={setShowFormContainer}
            />
          )}
        </Route>
      </div>
    </div>
  );
}

export default PortfolioList;
