import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ProfilePage.css";
import ProfileHeader from "./ProfileHeader";
import LoadingSpinner from "../Components/LoadingSpinner";
import Blog2 from "./Blog2";
import { userProfileData } from "../Components/SearchApi";

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { profileId } = useParams();

  useEffect(() => {
    userProfileData(profileId, setUser, setLoading);
  }, []);

  if (loading) {
    return <LoadingSpinner />; // Render loading spinner while data is being fetched
  }

  if (!user) {
    return <div>Error: Failed to fetch user data</div>;
  }

  return (
    <div className="UserProfileContainer">
      <div className="UserProfileContainerIn">
        <div className="backgroundColor">
          <div className="profile-container">
            <ProfileHeader
              name={user.name}
              email={user.email}
              photo={user.photo}
              id={user.userProfileId || user._id}
              about={user.about}
              isSameUser={user.isSameUser}
            />
          </div>
        </div>

        <div className="UserBlog">
          <div className="user-blogs">
            <h3>User Blogs</h3>
            <Blog2 userId={user._id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
