import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Editor from "./Editor";
import LikeButton from "./LikeButton";
import CommentSection from "./CommentSection";
import { editBlog, deleteBlog } from "../SearchApi";
import "./Blog CSS/SelectedBlog.css";

function SelectedBlog({
  blog,
  formatDate,
  author,
  date,
  title,
  blogId,
  setSelectedBlog,
}) {
  const { selectedBlog, editorStyles, userId, userName } = blog;

  const [isScrolling, setIsScrolling] = useState(false);
  const [editedBlog, setEditedBlog] = useState({ ...blog });
  const [editMode, setEditMode] = useState(false);

  const handleEditorChange = (content) => {
    setEditedBlog((prevBlog) => ({ ...prevBlog, content }));
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSaveEdit = async () => {
    try {
      const updatedBlog = {
        ...selectedBlog,
        title: editedBlog.title,
        topic: editedBlog.topic,
        content: editedBlog.content,
      };

      // Update the blog content in the database
      const success = await editBlog(blogId, updatedBlog);

      if (success) {
        //console.log('Blog edited successfully');
        setSelectedBlog(updatedBlog); // Update the local state with the edited blog
        setEditMode(false);
      } else {
        console.error("Error editing blog");
      }
    } catch (error) {
      console.error("Error editing blog post:", error);
    }
  };

  const handleDeleteBlog = async () => {
    try {
      if (blogId) {
        //console.log('Deleting blog with _id:', blogId);

        const success = await deleteBlog(blogId);

        if (success) {
          // If deletion is successful, set selectedBlog to null
          setSelectedBlog(null);
        }
      } else {
        console.error("No blog selected for deletion or blog ID is undefined");
      }
    } catch (error) {
      console.error("Error deleting blog post:", error);
    }
  };

  const handleBackToBlogs = () => {
    setSelectedBlog(null);
    setEditMode(false); // Switch to view mode
  };

  // Effect to listen for scroll events
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Effect to handle scroll events
  useEffect(() => {
    if (isScrolling) {
      // Implement your logic for scroll events here
      setIsScrolling(false);
    }
  }, [isScrolling]);

  return (
    <div className="SelectBlog-card-selected">
      <div className="selectblogtitle">
        <h2 className="selactbLogTitle">{title}</h2>
      </div>
      <div className="Selectblogauthor-and-date">
        <div className="Selectblogauthor-container">Author:&nbsp;{author}</div>

        <div className="Selectblogdate-container">
          Date:&nbsp;{formatDate(date)}
        </div>
      </div>
      {editMode ? (
        <div className="selectBlogEdit">
          {/* ... */}
          <Editor
            value={editedBlog.content}
            onChange={handleEditorChange}
            editMode={editMode}
            editorStyles={editorStyles}
          />
        </div>
      ) : (
        <div className="selectBlogEditinEdit">
          <Editor value={blog.content} editorStyles={editorStyles} />
          <div className="SelectBloglike-comment-container">
            <LikeButton
              className="SelectBlogLike"
              blogId={blogId}
              userId={userId}
            />
            <CommentSection
              className="selectBlogComment"
              blogId={blogId}
              userId={userId}
              userName={userName}
            />
          </div>
        </div>
      )}
      <div className="selectBlogButtons">
        <div className="backBlogButtons">
          {userName === blog.author && (
            <>
              {editMode ? (
                <button type="button" onClick={handleSaveEdit}>
                  Save Changes
                </button>
              ) : (
                <button type="button" onClick={handleEdit}>
                  Edit Blog
                </button>
              )}
            </>
          )}
          <button type="button" onClick={handleBackToBlogs}>
            Back to Blogs
          </button>
          {userName === blog.author && (
            <button type="button" onClick={handleDeleteBlog}>
              Delete Blog
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

SelectedBlog.propTypes = {
  blog: PropTypes.shape({
    selectedBlog: PropTypes.object,
    editedBlog: PropTypes.object,
    editMode: PropTypes.bool,
    handleEditorChange: PropTypes.func,
    editorStyles: PropTypes.string,
    userId: PropTypes.string,
    userName: PropTypes.string,
    setEditMode: PropTypes.func,
    editBlog: PropTypes.func,
  }),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onBack: PropTypes.func,
  formatDate: PropTypes.func,
};

SelectedBlog.defaultProps = {
  editorStyles: "",
};

export default SelectedBlog;
