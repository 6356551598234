import React, { useState } from "react";
import { connect } from "react-redux";
import { postCall } from "../../http-services";
import actionTypes from "../../store/actions";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import LoadingSpinner from "../../Components/LoadingSpinner";
import "./resetPassword.css";
import { useHistory } from "react-router-dom";
import { ADD_MESSAGE } from "../../Components/Redux/actions";

const ResetPassword = ({ setLoading, addMessage }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoadingState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const isInvalidEmail = (email) => {
    return (
      !email || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    );
  };

  const handleResetPassword = () => {
    setLoadingState(true);
    setErrorMessage("");

    let message = "";

    if (isInvalidEmail(email)) {
      message = "Invalid Email. Please try again.";
    }

    if (message) {
      addMessage(message);
      setLoadingState(false);
      return;
    }

    postCall(setLoading)(getBaseApi() + BackendAPIs.auth.resetPwd, {
      email,
    })
      .then((resp) => {
        setLoadingState(false);
        history.push("/reset-pwd/" + resp.redirect);
        addMessage("Send OTP to Reset Password, Please Check Email");
      })
      .catch((error) => {
        setLoadingState(false);
        addMessage("Failed to reset password. Please try again.");
      });
  };

  return (
    <div className="reset-pswd">
      {loading && <LoadingSpinner />}
      <div className="page-center">
        <h1>Reset Password</h1>
        <div className="user-name">
          <div className="lbl">Email</div>
          <div className="input-icon">
            <input
              autoComplete="off"
              className={`input-ctrl${
                isInvalidEmail(email) ? " error-class" : " noerror-class"
              }`}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
            />
          </div>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <div className="login-btn">
          <button
            className="login-btn"
            onClick={handleResetPassword}
            disabled={isInvalidEmail(email) || loading}
          >
            {loading ? "Sending OTP..." : "Send OTP to Reset Password"}
          </button>
        </div>
        <div className="sign-up-link">
          <p className="in-case">Don’t have an account?</p>
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/signup");
            }}
          >
            Sign Up Now
          </p>
        </div>
        <div className="sign-up-link">
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/");
            }}
          >
            &larr; Go back to home
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
    addMessage: (message) =>
      dispatch({
        type: ADD_MESSAGE,
        payload: message,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
