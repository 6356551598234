import { connect } from "react-redux";
import actionTypes from "../../store/actions";
import { useEffect, useState } from "react";
import { postCall } from "../../http-services";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import { useParams } from "react-router";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../Components/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./ValidateEmail.css";
import { ADD_MESSAGE } from "../../Components/Redux/actions";

const ValidateEmail = (props, {addMessage}) => {
  let { id } = useParams();
  let [loading, setLoading] = useState(true);
  let [response, setResponse] = useState(null);

  useEffect(() => {
    postCall(props.setLoading)(
      getBaseApi() + BackendAPIs.auth.validateEmail,
      {
        id: id,
      },
      false,
      null,
      false,
      true
    ).then((resp) => {
      setResponse(resp.data || "failed");
      setLoading(false);
    });
  }, [props.setLoading, id]);

  return (
    <div className="validate-email">
      <div className="validate-emailpage-center">
        <div className="validate-emailcard" id="validation-message">
          <div className="validate-emailcard-body validate-emailtext-center">
            {loading ? (
              <LoadingSpinner />
            ) : response === "success" ? (
              <FontAwesomeIcon
                icon={faCheck}
                className="validate-emailcolor-green validate-emailfa-5x"
              />
            ) : (
              <span
                role="img"
                aria-label="Failed"
                className="validate-emailcolor-red validate-emailfa-solid validate-emailfa-xmark validate-emailfa-7x"
              ></span>
            )}
            {!loading && response === "success" ? (
              <p className="validate-emailmb-0">
                Your email is successfully verified.{" "}
                <Link to="/login">Login here</Link>
              </p>
            ) : (
              !loading && (
                <p className="validate-emailmb-0">
                  The verification link has expired or already used.{" "}
                  <Link to="/signup">Please signup again</Link>
                </p>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
    addMessage: (message) =>
      dispatch({
        type: ADD_MESSAGE,
        payload: message,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateEmail);
