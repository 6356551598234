import React from "react";
import { useHistory } from "react-router-dom";
import api from "../../api.js";
import "../Css/PortfolioList.css";
import TemplateItem from "./TemplateItem.jsx";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function AllTemplates({ templates, setShowFormContainer }) {
  // Ensure templates is an array before calling map
  const templateArray = Array.isArray(templates) ? templates : [];

  //console.log(templateArray, " temp ", templates);

  const history = useHistory();

  const handleDemoClick = async (templateId) => {
    try {
      // Fetch the demo link for the selected template from the server
      const demoLinkResponse = await api(
        `${API_BASE_URL}/api/demo/${templateId}`
      );

      //console.log("Demo Link Data:", demoLinkResponse);

      // Check if the demo link is available
      if (demoLinkResponse && demoLinkResponse.demo) {
        // Open a new window with the demo link
        window.open(demoLinkResponse.demo, "_blank");
      } else {
        console.error("Demo link not available for template:", templateId);
      }
    } catch (error) {
      console.error("Error fetching demo link data", error);
    }
  };

  const handleTryClick = async (
    templateId,
    templateName,
    templateDetails,
    templateComponents
  ) => {
    //console.log("Trying Template:", templateId);
    //console.log(templateComponents);
    try {
      const templateDetailsToSend = {
        templateId: templateId,
        name: templateName,
        details: templateDetails,
        formOrder: templateComponents,
      };

      const saveAndTryTemplateResponse = await api(
        `${API_BASE_URL}/api/saveAndTryTemplate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(templateDetailsToSend),
        }
      );

      //console.log("try ", saveAndTryTemplateResponse);
      //const saveAndTryTemplateData = await saveAndTryTemplateResponse.json();

      ////console.log(saveAndTryTemplateData.formOrder.templateId);

      if (saveAndTryTemplateResponse.success) {
        //console.log("Template details and form order updated successfully");
        // Redirect to the tryForm page
        history.push("/workspace");
      } else {
        console.error(
          "Error saving and trying template:",
          saveAndTryTemplateResponse.error || saveAndTryTemplateResponse.message
        );
      }
    } catch (error) {
      console.error("Error saving and trying template:", error);
    }
  };

  const handleWorkspaceClick = async () => {
    history.push("/workspace");
  };

  const handleCustomiseClick = async () => {
    history.push("/customise");
  };

  return (
    <div className="AllTemplatesCount">
      <h2 className="AllTemplatesH">All Templates</h2>
      <ul className="SelectedPortfolioTamplates">
        {templateArray.map((template, index) => (
          <li key={index}>
            {/* <div>
              <h3 className="AllTemplatesCH">{template.name}</h3>
              <p className="AllTemplatesCP">{template.details}</p>
              <div className="AllTemplatesBtn">
                <button
                  className="AllTemplatesDemo"
                  onClick={() => handleDemoClick(template.id)}
                >
                  Demo
                </button>
                <button
                  className="AllTemplatesTry"
                  onClick={() =>
                    handleTryClick(
                      template.id,
                      template.name,
                      template.details,
                      template.components
                    )
                  }
                >
                  Try
                </button>
              </div>
            </div> */}
            <TemplateItem
              template={template}
              handleDemoClick={handleDemoClick}
              handleTryClick={handleTryClick}
            />
          </li>
        ))}
      </ul>
      {/* <div className="buttonscustworkA">
        <button
          className="WorkspaceCustomiseButtonAllTemp"
          onClick={handleCustomiseClick}
        >
          Customise Template
        </button>
        <button
          className="WorkspaceButtonAllTemplate"
          onClick={handleWorkspaceClick}
        >
          Go to Workspace
        </button>
      </div> */}
    </div>
  );
}

export default AllTemplates;
