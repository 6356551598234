import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import defaultPhotoUrl from "../assets/images/User.png";
import LoadingSpinner from "../Components/LoadingSpinner";
import { userProfilePic } from "../Components/SearchApi";

const ProfilePhoto = ({ initialPhoto, isSameUser }) => {
  let userPhoto = null;

  if (initialPhoto) {
    userPhoto = `data:image/png;base64,${initialPhoto}`;
  }

  const fileInputRef = useRef(null);
  const [photo, setPhoto] = useState(userPhoto || defaultPhotoUrl); // State to hold the photo URL
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { profileId } = useParams();

  const handlePhotoClick = () => {
    if (isSameUser) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setError(null);

      try {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64Data = reader.result.replace(
            /^data:image\/\w+;base64,/,
            ""
          );
          userProfilePic(profileId, base64Data, setIsLoading, setError);
        };
        reader.readAsDataURL(selectedFile);
      } catch (err) {
        setError("Error uploading photo.");
        setIsLoading(false);
      }

      // Simulating image upload process with setTimeout
      setTimeout(() => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPhoto(reader.result);
          setIsLoading(false);
        };
        reader.onerror = () => {
          setError("Error uploading photo.");
          setIsLoading(false);
        };
        reader.readAsDataURL(selectedFile);
      }, 10); // Simulating 1 second delay
    }
  };

  return (
    <div className={`profile-photo-container${isSameUser ? ' same-user' : ''}`} onClick={handlePhotoClick}>
      {isLoading && <LoadingSpinner />}
      <img src={photo} alt="User" className="profile-photo" />
      {isSameUser && (
        <>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
            className="curserPointer"
          />
          <div className="update-profile-container">
            <span className="update-profile-text">Update Profile</span>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfilePhoto;
