import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import restaurantImage from "../../assets/images/Chef.gif";
import personalImage from "../../assets/images/personal.gif";
import retailImage from "../../assets/images/retail.gif";

const ProductCard = () => {
  const [animate, setAnimate] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setAnimate(true);
  }, []);

  const portfolioMappings = {
    Personal: "Portfolio1",
    Retail: "Portfolio2",
    Restaurant: "Portfolio3",
  };

  const handleButtonClick = () => {
    history.push("/products");
  };

  const handleCategoryClick = (category) => {
    const identifier = portfolioMappings[category];

    if (identifier) {
      history.push(`/products/${identifier}`);
    } else {
      console.error(`Identifier not found for category: ${category}`);
    }
  };

  const categoryDetails = [
    {
      name: "Restaurant",
      title: "Dine in Style: Engaging Restaurant Sites",
      author: "",
      Like: "4k",
      image: restaurantImage,
    },
    {
      name: "Personal",
      title: "Express Yourself: Unique Personal Sites",
      author: "",
      Like: "2k",
      image: personalImage,
    },
    {
      name: "Retail",
      title: "Shop Your Style: Retail Sites That Sell",
      author: "",
      Like: "7k",
      image: retailImage,
    },
  ];

  return (
    <div className={`ProductCardcardsss ${animate ? "animate" : ""}`}>
      <div>
        <div className="HeaderMidProduct">
          <h1 className="HeaderMidProductP">
            Choose your perfect fit with our visual templates gallery Simple,
            fast, tailored
          </h1>
        </div>
      </div>

      <section className="ProductCardcards">
        {categoryDetails.map((category) => (
          <article
            className={`ProductCardcard ProductCardcard--1`}
            key={category.name}
            onClick={() => handleCategoryClick(category.name)}
          >
            <div className="ProductCardcard__info-hover">
              <svg className="ProductCardcard__like" viewBox="0 0 24 24">
                {/* SVG path */}
              </svg>
              <div className="ProductCardcard__clock-info">
                <svg className="ProductCardcard__clock" viewBox="0 0 24 24">
                  {/* SVG path */}
                </svg>
                <span className="ProductCardcard__time">{category.Like}</span>
              </div>
            </div>
            <div
              className="ProductCardcard__img"
              style={{ backgroundImage: `url(${category.image})` }}
            ></div>
            <a href="" className="ProductCardcard_link">
              <div
                className="ProductCardcard__img--hover"
                style={{ backgroundImage: `url(${category.image})` }}
              ></div>
            </a>
            <div className="ProductCardcard__info">
              <span className="ProductCardcard__category">
                {" "}
                {category.name}{" "}
              </span>
              <h3 className="ProductCardcard__title">{category.title}</h3>
              <span className="ProductCardcard__by">
                {/* by{" "} */}
                <a href="" className="ProductCardcard__author" title="author">
                  {category.author}
                </a>
              </span>
            </div>
          </article>
        ))}
      </section>
    </div>
  );
};

export default ProductCard;
