import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import actionTypes from "../../store/actions";
import { postCall } from "../../http-services";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import './Welcome.css';

const Welcome = ({ isLoading, setLoading }) => {
  let [email, setEmail] = useState("");
  useEffect(() => {
    postCall(() => {})(getBaseApi() + BackendAPIs.generic.getUserInfo, {}).then(
      (resp) => {
        setEmail(resp?.data?.email || "No Email");
      }
    );
  }, []);
  return (
    <div className="welcome">
      Hello {email}
      <br />
      <button
        onClick={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("email");
          window.location.href = "/";
        }}
      >
        Click here to logout
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
