import React, { useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import * as apiUrls from "../../apiUrls";

const DraggableItem = ({
  item,
  index,
  moveItem,
  handleDelete,
  sectionName,
  formOrder,
}) => {
  //console.log('Form Order Details:', formOrder);

  const [, ref] = useDrag({
    type: "DYNAMIC_SELECT_ITEM",
    item: { id: item.id, index },
  });

  const [, drop] = useDrop({
    accept: "DYNAMIC_SELECT_ITEM",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  // Check if formOrder is an array before using find
  const correspondingFormOrderItem = Array.isArray(formOrder)
    ? formOrder.find((orderItem) => orderItem.id === item.id)
    : null;

  return (
    <div ref={(node) => ref(drop(node))} style={{ cursor: "move" }}>
      <li key={item.id} className="DynamicSelecteContainerli">
        <div>
          <p>{item.name}</p>
          <p>{item.details}</p>

          {/* Display additional information from formOrder */}
          {correspondingFormOrderItem && (
            <p>
              Additional Information:{" "}
              {correspondingFormOrderItem.additionalInfo}
            </p>
          )}
        </div>
        <button
          onClick={() => handleDelete(item)}
          className="DynamicSelecteContainerbtn"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </li>
    </div>
  );
};

const DynamicSelecte = ({
  selectedItems,
  updateSelectedItems,
  handleDeleteItem,
  sectionName,
  formOrder,
}) => {
  const [templateName, setTemplateName] = React.useState("");

  useEffect(() => {
    //console.log('Selected Items Updated:', selectedItems);
    //console.log('Form Order:', formOrder);

    if (
      formOrder &&
      Array.isArray(formOrder.order) &&
      formOrder.order.includes("templateName")
    ) {
      //console.log('Template Name Item found in formOrder order array');
      setTemplateName(formOrder.templateName || "");
    } else if (formOrder && formOrder.templateName) {
      //console.log('Template Name Item found in formOrder directly');
      setTemplateName(formOrder.templateName || "");
    } else {
      //console.log('Template Name Item not found in formOrder.');
    }
  }, [selectedItems, formOrder]);

  //console.log(selectedItems);
  //console.log(formOrder);

  const handleDelete = (itemToDelete) => {
    const updatedItems = selectedItems.filter(
      (item) => item.id !== itemToDelete.id
    );
    updateSelectedItems(updatedItems);
    handleDeleteItem(itemToDelete);
  };

  const moveItem = (fromIndex, toIndex) => {
    const updatedItems = [...selectedItems];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    updateSelectedItems(updatedItems);
  };

  const handleCheckTemplateName = async () => {
    const checkTemplateResponse = await api(apiUrls.checkTemplateNameUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ templateId: "Deraived", templateName }),
    });

    const checkTemplateData = await checkTemplateResponse;

    if (checkTemplateData.exists) {
      if (checkTemplateData.updateAllowed) {
        const userConfirmed = window.confirm(checkTemplateData.message);
        if (userConfirmed) {
          await handleUpdateTemplateName();
        } else {
          alert("Please choose a different template name.");
        }
      } else {
        alert(checkTemplateData.message);
      }
    } else {
      await handleSave();
    }
  };

  const handleUpdateTemplateName = async () => {
    const userConfirmed = window.confirm(
      "Do you want to update the template name?"
    );
    if (userConfirmed) {
      const updateTemplateNameResponse = await api(
        apiUrls.updateTemplateNameUrl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ templateName, newName: templateName }),
        }
      );

      const updateTemplateNameData = await updateTemplateNameResponse;

      if (updateTemplateNameData.success) {
        //console.log('Template name updated successfully:', updateTemplateNameData.message);

        // Update the template name in the state
        setTemplateName(templateName);

        // Proceed with saving the template
        await handleSave();
      } else {
        console.error(
          "Failed to update template name:",
          updateTemplateNameData.error
        );
        alert("Failed to update template name. Please try again.");
      }
    } else {
      alert("Please choose a different template name.");
    }
  };

  const handleSave = async () => {
    if (!templateName.trim()) {
      alert("Please enter a template name.");
      return;
    }

    const requestData = {
      templateId: "Derived",
      name: templateName,
      details: "Details for the template",
      formOrder: selectedItems.map((item) => ({
        name: item.sectionName,
        id: item.id,
      })),
    };

    try {
      const response = await api(apiUrls.saveAndTryTemplateUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response) {
        const responseData = await response;
        //console.log('Template and form order saved successfully:', responseData);
        window.open("/workspace", "_parent");
      } else {
        console.error(
          "Failed to save template and form order:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error saving template and form order:", error);
    }
  };

  return (
    <div className="DynamicSelecteContainer">
      <h2>Selected Components:</h2>
      <div className="DynamicSelecteDivinput">
        <input
          type="text"
          id="templateName"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          placeholder="Enter template name"
          className="DynamicSelectinputTemp"
        />
      </div>
      <ul className="DynamicSelecteContainerul">
        {selectedItems.map((item, index) => (
          <React.Fragment key={item.id}>
            <DraggableItem
              item={item}
              index={index}
              moveItem={moveItem}
              handleDelete={handleDelete}
              sectionName={sectionName}
              formOrder={formOrder}
            />
            {index < selectedItems.length - 1 && (
              <div className="ArrowContainerSelect">
                <FontAwesomeIcon
                  icon={faArrowDown}
                  className="ArrowIconSelect"
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </ul>

      {selectedItems.length > 0 && (
        <button onClick={handleCheckTemplateName} className="DynamicSelecteBtn">
          Save
        </button>
      )}
    </div>
  );
};

export default DynamicSelecte;
