// SearchApi.js
import api from "../api";

import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const fetchBlogs = async (searchTerm) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/getBlogs`);

    // Filter results based on the search term
    const filteredResults = response.data.filter(
      (result) =>
        result.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        result.topic.toLowerCase().includes(searchTerm.toLowerCase()) ||
        result.author.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Sort results by date in descending order
    const sortedResults = filteredResults.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );

    return sortedResults;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchUsers = async (searchTerm) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/getUserByName/${searchTerm}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchSearchResults = async ({ searchTerm, activeButton }) => {
  try {
    const response = await (activeButton === "blogs"
      ? fetchBlogs(searchTerm)
      : fetchUsers(searchTerm));
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchData = async (
  selectedBlog,
  setBlogData,
  setFilteredBlogData,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    const response = await fetch(`${API_BASE_URL}/api/getBlogs`);
    if (response.ok) {
      const data = await response.json();
      const sortedData = data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setBlogData(sortedData);
      setFilteredBlogData(sortedData);
    } else {
      console.error(
        "Error fetching blog data:",
        response.status,
        response.statusText
      );
    }
  } catch (error) {
    console.error("Error fetching blog data:", error);
  } finally {
    setIsLoading(false); // Data fetch hone ke baad loading state false
  }
};

const fetchUserBlog = async (
  selectedBlog,
  setBlogData,
  setFilteredBlogData,
  userId,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    const response = await fetch(`${API_BASE_URL}/api/getBlogs/${userId}`);
    if (response.ok) {
      const data = await response.json();
      const sortedData = data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setBlogData(sortedData);
      setFilteredBlogData(sortedData);
    } else {
      console.error(
        "Error fetching blog data:",
        response.status,
        response.statusText
      );
    }
  } catch (error) {
    console.error("Error fetching blog data:", error);
  } finally {
    setIsLoading(false); // Data fetch hone ke baad loading state false
  }
};

const fetchBlogDetails = async (blogId, setBlogDetails) => {
  try {
    const response = await api(`${API_BASE_URL}/api/getBlogDetails/${blogId}`);

    if (!response) {
      throw new Error(
        `Error fetching blog details: ${response.status} ${response.statusText}`
      );
    }

    setBlogDetails(response);
  } catch (error) {
    console.error("Error fetching blog details:", error);
    throw error;
  }
};

const fetchBlogContent = async (blogId, setSelectedBlog, setIsLoading) => {
  try {
    setIsLoading(true);
    const response = await api(`${API_BASE_URL}/api/getBlogContent/${blogId}`);

    if (!response) {
      throw new Error(
        `Error fetching blog content: ${response.status} ${response.statusText}`
      );
    }

    setSelectedBlog((prevBlog) => ({ ...prevBlog, content: response.content }));
  } catch (error) {
    console.error("Error fetching blog content:", error);
  } finally {
    setIsLoading(false); // Set loading state to false when data fetching is done
  }
};

const editBlog = async (blogId, editedBlog) => {
  try {
    const response = await api(`${API_BASE_URL}/api/editBlog/${blogId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: editedBlog.title,
        topic: editedBlog.topic,
        content: editedBlog.content,
        contentId: editedBlog.contentId,
      }),
    });

    // console.log("Response:", response); // Log the response object

    if (response) {
      //console.log("Blog edited successfully");
      return true;
    } else {
      console.error(
        "Error editing blog:",
        response.status,
        response.statusText
      );
      return false;
    }
  } catch (error) {
    console.error("Error editing blog post:", error);
    return false;
  }
};

export const deleteBlog = async (blogId) => {
  try {
    const response = await api(`${API_BASE_URL}/api/deleteBlog/${blogId}`, {
      method: "DELETE",
    });

    if (!response) {
      throw new Error(
        `Error deleting blog: ${response.status} ${response.statusText}`
      );
    }

    return true;
  } catch (error) {
    console.error("Error deleting blog post:", error);
    return false;
  }
};

const fetchSearchBlogContent = async (blogId, setSelectedBlogContent) => {
  try {
    const response = await api(`${API_BASE_URL}/api/getBlogContent/${blogId}`);

    if (response.ok) {
      const data = await response.json();

      // Assuming setSelectedBlogContent is a callback function
      if (typeof setSelectedBlogContent === "function") {
        setSelectedBlogContent((prevBlog) => ({
          ...prevBlog,
          content: data.content,
        }));
      } else {
        console.error("setSelectedBlogContent is not a function");
      }

      return setSelectedBlogContent;
    } else {
      console.error(
        "Error fetching blog content:",
        response.status,
        response.statusText
      );
      throw new Error("Error fetching blog content");
    }
  } catch (error) {
    console.error("Error fetching blog content:", error);
    throw error;
  }
};

const createBlog = async (blogData) => {
  try {
    const response = await api(`${API_BASE_URL}/api/createBlog`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(blogData),
    });

    if (!response) {
      throw new Error(
        `Error creating blog: ${response.status} ${response.statusText}`
      );
    }

    return response;
  } catch (error) {
    console.error("Error creating blog:", error);
    throw error;
  }
};

const fetchComments = async (blogId) => {
  try {
    const response = await api(`${API_BASE_URL}/api/getComments/${blogId}`);

    if (!response) {
      throw new Error(
        `Error creating blog: ${response.status} ${response.statusText}`
      );
    }

    const commentsData = await response;
    const sortedComments = commentsData.comments.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    return sortedComments;
  } catch (error) {
    console.error("Error fetching comments:", error);
    throw error;
  }
};

const submitComment = async (blogId, userName, newComment) => {
  try {
    const response = await api(`${API_BASE_URL}/api/commentBlog/${blogId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName,
        text: newComment,
        date: new Date(),
      }),
    });

    if (!response) {
      throw new Error(
        `Error creating blog: ${response.status} ${response.statusText}`
      );
    }

    const newCommentData = await response;
    return newCommentData.updatedBlog;
  } catch (error) {
    console.error("Error submitting comment:", error);
    throw error;
  }
};

const userProfileData = async (profileId, setUser, setLoading) => {
  try {
    const response = await api(
      `${API_BASE_URL}/api/userProfileData/${profileId}`
    );
    if (!response) {
      throw new Error("Failed to fetch user data");
    }
    const userData = await response;
    setUser(userData);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

const userProfileId = async (userId,setProfileId,setIsLoading) => {
  try {
    setIsLoading(true);
    const response = await fetch(
      `${API_BASE_URL}/api/userProfileId/${userId}`
    );
    if (!response) {
      throw new Error("Failed to fetch user data");
    }
    const userProfileId = await response.json();
    setProfileId(userProfileId);
  }
  catch (error) {
    console.error("Error fetching user data:", error);
  }
  finally {
    setIsLoading(false);
  }
}

const userProfilePic = async (
  profileId,
  base64Data,
  setIsLoading,
  setError
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/userprofile/${profileId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          photo: base64Data,
        }),
      }
    );

    if (response.ok) {
      // Parse response body as JSON
      const responseData = await response.json();
      // Set photo state with the data received from the server
      setIsLoading(false);
    } else {
      // Handle error responses
      console.error("Error uploading photo:", response.statusText);
      setError("Error uploading photo.");
      setIsLoading(false);
    }
  } catch (err) {
    console.error("Error uploading photo:", err);
    setError("Error uploading photo.");
    setIsLoading(false);
  }
};

const userProfileEditSave = async (
  profileId,
  editedAbout,
  editedId,
  editMode,
  setEditMode,
  history
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/userprofile/${profileId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          about: editedAbout,
          userProfileId: editedId,
        }),
      }
    );

    if (response.ok) {
      // If the update is successful, toggle edit mode
      setEditMode(!editMode);
      history.push(`${editedId}`);
    } else {
      console.error("Error updating user profile:", response.statusText);
      // Handle error response
    }
  } catch (err) {
    console.error("Error updating user profile:", err);
    // Handle fetch error
  }
};

const profilePicBytoken = async (setPhoto) => {
  try {
    const response = await api(
      `${API_BASE_URL}/api/profilePicBytoken`
    );
    if (!response) {
      throw new Error("Failed to fetch user data");
    }
    const userData = await response;
    setPhoto(userData.photo);
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

export {
  fetchBlogs,
  fetchUsers,
  fetchSearchResults,
  fetchData,
  fetchBlogContent,
  fetchSearchBlogContent,
  editBlog,
  fetchBlogDetails,
  createBlog,
  fetchComments,
  submitComment,
  fetchUserBlog,
  userProfileData,
  userProfilePic,
  userProfileEditSave,
  userProfileId,
  profilePicBytoken,
};

export const submitContactForm = async (formData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error submitting contact form:", error);
    throw error;
  }
};
