// PortfolioItem.js
import React from 'react';
import '../Css/PortfolioList.css'

function PortfolioItem({ id, name, details, onClick }) {
  return (
    <div className="portfolio-item" onClick={() => onClick(id)}>
      <h3 className="portfolioHeader">{name}</h3>
      <p className="portfolioDetails">{details}</p>
    </div>
  );
}

export default PortfolioItem;
