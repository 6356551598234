import React, { useState, useEffect } from "react";
import DomainSlider from "./DomainSlider";

const DomainPage = () => {
  const lines = [
    "yoursubdomain.ownsit.xyz",
    "yourbusinessdomain.ownsit.xyz",
    "yourwebsitedomain.ownsit.xyz",
  ];

  const [currentLine, setCurrentLine] = useState(0);
  const [currentText, setCurrentText] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentLine < lines.length) {
        if (currentText.length < lines[currentLine].length) {
          setCurrentText(lines[currentLine].slice(0, currentText.length + 1));
        } else {
          if (currentLine < lines.length - 1) {
            setTimeout(() => {
              setCurrentText("");
              setCurrentLine(currentLine + 1);
            }, 1000); // Wait for 1 second before moving to the next line
          } else {
            // Restart from the first line
            setTimeout(() => {
              setCurrentText("");
              setCurrentLine(0);
            }, 1000);
          }
        }
      }
    }, 300); // Typing speed: 300ms per character

    return () => clearTimeout(timer);
  }, [currentLine, currentText, lines]);

  return (
    <div className="domain-page">
      <div className="domainHeader">
        <h1> Choose Your Own Domain </h1>
      </div>
      <div className="typing-area">
        <div className="text-container">
          <div className="www">www.</div>
          <div className="typed-text">{currentText}</div>
        </div>
      </div>
    </div>
  );
};

export default DomainPage;
