import React from "react";
import {
  FaCode,
  FaPaintBrush,
  FaBullhorn,
  FaRocket,
  FaLaptop,
} from "react-icons/fa";

const Service = () => {
  const services = [
    {
      icon: <FaCode />,
      mainHeading: "Web Development",
      subHeading: "Design and Development",
      description:
        "We specialize in creating modern and responsive websites tailored to your business needs.",
    },
    {
      icon: <FaPaintBrush />,
      mainHeading: "Graphic Design",
      subHeading: "Creative Artwork",
      description:
        "Our creative team can design eye-catching graphics for your branding and marketing materials.",
    },
    {
      icon: <FaRocket />,
      mainHeading: "Template Driven Development",
      subHeading: "Reducing time to market",
      description:
        "We offer tailor made templates and which reduces to market your business",
    },
    {
      icon: <FaBullhorn />,
      mainHeading: "Social Media Management",
      subHeading: "Online Presence",
      description:
        "We offer comprehensive digital marketing solutions to boost your online presence and reach your target audience.",
    },
    {
      icon: <FaLaptop />,
      mainHeading: "Plug-ins",
      subHeading: "Plugin Development",
      description:
        "We develop plugins that can be customized to meet specific business requirements and automated manual tasks",
    },
  ];

  return (
    <div className="service-container">
      <div className="servicemain-heading">
      <h1 className="HeaderMidProductP">Our Services</h1>

      </div>
      {/* <h3 className="servicesub-heading">
        Innovative Solutions Tailored for You
      </h3> */}
      <div className="service-items-container">
        {services.map((service, index) => (
          <div
            className={`service-item ${index % 2 === 0 ? "even" : "odd"}`}
            key={index}
          >
            <div className="service-icon">{service.icon}</div>
            <div className="service-content">
              <h2 className="service-main-heading">{service.mainHeading}</h2>
              <h3 className="service-subheading">{service.subHeading}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
