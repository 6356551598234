import React, { useState } from "react";
import { connect } from "react-redux";
import { postCall } from "../../http-services";
import actionTypes from "../../store/actions";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import LoadingSpinner from "../../Components/LoadingSpinner";
import "./ConfirmResetPassword.css";
import { useHistory } from "react-router-dom";
import { ADD_MESSAGE } from "../../Components/Redux/actions";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ConfirmResetPassword = ({ setLoading, addMessage }) => {
  const [otp, setOTP] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setCPassword] = useState("");
  const [loading, setLoadingState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);

  const history = useHistory();
  const { id } = useParams();

  const isInvalidPassword = (pwd) => {
    return (
      pwd.length < 8 ||
      pwd.length > 15 ||
      !pwd.match(/[a-z]/g) ||
      !pwd.match(/[0-9]/g)
    );
  };

  const isInvalidCPwd = (pwd, cPwd) => {
    return (
      pwd.length < 8 ||
      pwd.length > 15 ||
      !pwd.match(/[a-z]/g) ||
      !pwd.match(/[0-9]/g) ||
      pwd !== cPwd
    );
  };

  const isInvalidOTP = (otp) => {
    return !otp || otp.length !== 6;
  };

  const handleResetPassword = () => {
    setLoadingState(true);
    setErrorMessage("");
    setSuccessMessage("");

    let message = "";

    if (isInvalidPassword(password)) {
      message = "Invalid password. Please try again.";
    } else if (isInvalidCPwd(password, confirmPassword)) {
      message = "Passwords do not match or do not meet requirements.";
    } else if (isInvalidOTP(otp)) {
      message = "Invalid OTP. Please try again.";
    }

    if (message) {
      addMessage(message);
      setLoadingState(false);
      return;
    }

    postCall(setLoading)(getBaseApi() + BackendAPIs.auth.resetPwdAction, {
      id,
      otp,
      password,
      confirmPassword,
    })
      .then((resp) => {
        setLoadingState(false);
        if (resp.data) {
          addMessage("Password Changed Successfully!");
          setTimeout(() => {
            history.push("/login");
          }, 2000);
        } else {
          addMessage("Invalid OTP or passwords do not match.");
        }
      })
      .catch((error) => {
        setLoadingState(false);
        addMessage("An error occurred. Please try again later.");
      });
  };

  return (
    <div className="reset-pwd-action">
      <div className="page-center">
        <h1>Reset Password</h1>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}
        <div className="user-name">
          <div className="lbl">OTP</div>
          <div className="input-icon">
            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvalidOTP(otp) ? " error-class" : " noerror-class")
              }
              type="text"
              value={otp}
              onChange={(e) => {
                setOTP(e.target.value);
              }}
              placeholder="OTP (received over mail)"
            />
          </div>
        </div>
        <div className="pswd">
          <div className="lbl">New Password</div>
          <div className="input-icon">
            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvalidPassword(password)
                  ? " error-class"
                  : " noerror-class")
              }
              type={showPassword && password.length > 0 ? "text" : "password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
            />
            {password.length > 0 && (
              <span
                className="toggle-password"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            )}
          </div>
        </div>
        <div className="cnfm-pswd">
          <div className="lbl">Confirm Password</div>
          <div className="input-icon">
            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvalidCPwd(password, confirmPassword)
                  ? " error-class"
                  : " noerror-class")
              }
              type={
                showPasswordC && confirmPassword.length > 0
                  ? "text"
                  : "password"
              }
              value={confirmPassword}
              onChange={(e) => {
                setCPassword(e.target.value);
              }}
              placeholder="Confirm Password"
            />
            {confirmPassword.length > 0 && (
              <span
                className="toggle-password"
                onClick={() => {
                  setShowPasswordC(!showPasswordC);
                }}
              >
                {showPasswordC ? <FaEye /> : <FaEyeSlash />}
              </span>
            )}
          </div>
        </div>
        <div className="reset-btn">
          <button
            className="reset-btn"
            onClick={handleResetPassword}
            disabled={loading}
          >
            Reset Password
          </button>
        </div>
        <div className="login-link">
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/");
            }}
          >
            &larr; Go back to home
          </p>
        </div>
      </div>
      {loading && <LoadingSpinner />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
    addMessage: (message) =>
      dispatch({
        type: ADD_MESSAGE,
        payload: message,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmResetPassword);
