// SearchNavbar.jsx
import React, { useState } from 'react';
import '../../Css/SearchNavbar.css';
import SearchINSearch from '../SearchINSearch';


const SearchNavbar = () => {

  return (
    <nav className="SeacrhMainNav">
      <div className="SearchHeader">
        <h2> Search... </h2>
      </div>

      <div className="SearchInSearchNav">
        <SearchINSearch />
      </div>
    </nav>
  );
};

export default SearchNavbar;
