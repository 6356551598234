import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../assets/images/doamin1.png";
import image2 from "../../assets/images/domain2.png";

const DomainSlider = () => {
  const websites = [
    { image: image1, link: "https://shop.ownsit.xyz/" },
    { image: image2, link: "https://jayant.sogani.in/" },
    // Add more customer websites as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // 3 seconds per slide
    centerMode: true,
    centerPadding: "0px", // Adjust as needed
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "25%",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="">
      <div className="domainSliderWrapper">
        {/* <div className="DomainSliderHeader">
          <h1>Our Domain Selector</h1>
        </div> */}
        <div className="domainSlider">
          <Slider {...settings}>
            {websites.map((website, index) => (
              <div key={index} className="slide">
                <a
                  href={website.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={website.image}
                    alt={`Website ${index + 1}`}
                    className="sliderDomain"
                  />
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default DomainSlider;
