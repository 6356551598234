// HomePage.js
import React from "react";
import { useHistory } from "react-router-dom";
import "../Css/LandingPage.css";

function HomePage() {
  const history = useHistory();

  const portfolioMappings = {
    Personal: "Portfolio1",
    Retail: "Portfolio2",
    Restaurant: "Portfolio3",
  };

  const handleButtonClick = () => {
    history.push("/products");
  };

  const handleCategoryClick = (category) => {
    const identifier = portfolioMappings[category];

    if (identifier) {
      history.push(`/products/${identifier}`);
    } else {
      console.error(`Identifier not found for category: ${category}`);
    }
  };

  return (
    <div className="HeaderHome">
      <div className="LandingPContent">
        <h1>Transforming BUSINESS into Online Reality</h1>
      </div>
      <div className="HeaderMid">
        <p className="HeaderMidP">
          Crafting stuning, fully-mangaed website, ensuring a seamless online
          presense for your business.
        </p>
      </div>
      {/* <div className="LandingTag">
        {['Personal', 'Retail', 'Restaurant'].map((category) => (
          <a key={category} onClick={() => handleCategoryClick(category)}>
            {category}
          </a>
        ))}
      </div> */}
      <div className="LandingButton">
        <button className="btn" type="button" onClick={handleButtonClick}>
          <strong className="btnStrong"> Get Started </strong>
          <div id="container-stars">
            <div id="stars"></div>
          </div>
          <div id="glow">
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </button>
      </div>
    </div>
  );
}

export default HomePage;
