import logo from "../assets/images/icon.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/" className="brand-logo">
      <img src={logo} alt="logo" style={{ height: "5rem" }} />
      <div className="logo-text"> Your App Name</div>
    </Link>
  );
};

export default Logo;
