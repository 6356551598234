import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faBell } from "@fortawesome/free-solid-svg-icons";
import "../Css/PortfolioNavbar.css";

const PortfolioNavbar = () => {
  const history = useHistory();

  const portfolioMappings = {
    "Personal Portfolio": "Portfolio1",
    "Retail Portfolio": "Portfolio2",
    "Restaurant Portfolio": "Portfolio1",
  };

  const handleCategoryClick = (category) => {
    const identifier = portfolioMappings[category];

    if (identifier) {
      history.push(`/products/${identifier}`);
    } else {
      console.error(`Identifier not found for category: ${category}`);
    }
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("eamil");
    setIsLoggedIn(false);
    history.push("/logout");
  };

  return (
    <nav className="PortfolioMainNav">
      <div className="Portfolionavbarcontainer">
        <Link to="/" className="PortfoliologoLink">
          <div className="Portfoliologo">
            <img src="/images/logo.PNG"></img>
            <a id="Portfolioowns">Owns</a>
            <a id="Portfolioit">It</a>
          </div>
        </Link>

        <div className="Portfoliomiddlemenu">
          <div className="Portfoliomenuitem">
            <a href="#">Products</a>
            <div className="Portfoliosubmenu">
              {[
                "Personal Portfolio",
                "Retail Portfolio",
                "Restaurant Portfolio",
                "Inventory Management",
                "Business Portfolio",
              ].map((category) => (
                <a key={category} onClick={() => handleCategoryClick(category)}>
                  {category}
                </a>
              ))}
            </div>
          </div>

          <div className="Portfoliomenuitem">
            <a href="#">Resources</a>
            <div className="Portfoliosubmenu">
              <a href="#">Resource 1</a>
              <a href="#">Resource 2</a>
              <a href="#">Resource 3</a>
            </div>
          </div>
          <div className="Portfoliomenuitem">
            <a href="/blogs">Blogs</a>
          </div>
        </div>

        <div className="left-symbols">
          <div className="notification-symbol">
            {" "}
            <FontAwesomeIcon icon={faBell} />
          </div>
        </div>

        <div className="Portfoliorightmenu">
          <div className="Portfoliologin">
            {isLoggedIn ? (
              <div className="loginNavDiv">
                <a href="/" onClick={handleLogout}>
                  <div className="logout-symbol">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </div>
                </a>
              </div>
            ) : (
              <a href="/login">Login</a>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default PortfolioNavbar;
