// Blog.js
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, useHistory } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import "./Blog.css";
import SearchComponent from "./SearchComponent";
import BlogDetails from "../Components/Blog/BlogDetails";
import { fetchUserBlog } from "../Components/SearchApi";
import LoadingSpinner from "../Components/LoadingSpinner";
import defaultPhotoUrl from "../assets/images/User.png";

function Blog2({ userId }) {
  const history = useHistory();
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [blogData, setBlogData] = useState([]);
  const [filteredBlogData, setFilteredBlogData] = useState(blogData);
  const [blogsToShow, setBlogsToShow] = useState(10);
  const [isScrolling, setIsScrolling] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [userName, setUserName] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("name");
    setUserName(name || "");
  }, []);

  // useEffect(() => {
  //   fetchData(currentPage, setBlogData, setFilteredBlogData, allBlogData);
  // }, [currentPage]);

  useEffect(() => {
    setIsLoading(true);
    fetchUserBlog(selectedBlog, setBlogData, setFilteredBlogData, userId, setIsLoading);
  }, [selectedBlog]);

  const handleScroll = () => {
    setIsScrolling(true);
  };

  // Effect to listen for scroll events
  useEffect(() => {
    window.addEventListener("scroll", handleScroll());
    return () => {
      window.removeEventListener("scroll", handleScroll());
    };
  }, [isScrolling]);

  // Effect to fetch more blogs when scrolling near the bottom
  useEffect(() => {
    if (isScrolling) {
      const isScrollingNearBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;

      // console.log(
      //   "window.innerHeight",
      //   window.innerHeight,
      //   "window.scrollY",
      //   window.scrollY,
      //   "document.body.offsetHeight",
      //   document.body.offsetHeight,
      //   "isScrollingNearBottom",
      //   isScrollingNearBottom
      // );

      if (isScrollingNearBottom && blogsToShow < blogData.length) {
        const newBlogsToShow = blogsToShow + 10;
        setBlogsToShow(newBlogsToShow);
        const newBlogs = blogData.slice(0, newBlogsToShow);
        setFilteredBlogData(newBlogs);
      }

      setIsScrolling(false);
    }
  }, [isScrolling, blogsToShow, blogData]);

  const handleBlogClick = async (blogId) => {
    setIsLoading(true);
    setScrollPosition(window.scrollY);
    setSelectedBlogId(blogId);

    const token = localStorage.getItem("token");
    if (!token) {
      const fullURL = `/blogs/${blogId}`;
      const redirectURL = encodeURIComponent(fullURL);

      if (window.location.hostname === "localhost") {
        document.cookie = `redirectUrl=${redirectURL}; expires=Thu, 01 Jan 2030 00:00:00 UTC; path=/`;
      } else {
        document.cookie = `redirectUrl=${redirectURL}; expires=Thu, 01 Jan 2030 00:00:00 UTC; Domain=ownsit.xyz; Secure; SameSite=Lax; path=/`;
      }

      setIsLoading(false);
      history.push("/login");
      return;
    }

    // if (blogId !== "new") {
    //   // Fetch the selected blog's content
    //   fetchBlogContent(blogId, setSelectedBlog);
    // }

    // Redirect to the blog details page
    history.push(`/blogs/${blogId}`);
  };

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-IN", options);
  }

  // const handleLogin = (name) => {
  //   setUserName(name);
  //   setShowLoginModal(false);
  // };

  const handleSearch = (searchTerm) => {
    setIsLoading(true);
    const filteredData = blogData.filter(
      (blog) =>
        blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.topic.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setIsLoading(false);
    setFilteredBlogData(filteredData);
  };

  const handleBackToBlogs = () => {
    setSelectedBlogId(null);
    setBlogsToShow(10);
    setFilteredBlogData(blogData.slice(0, 10));
    window.scrollTo(0, scrollPosition);
  };

  const handleCreateBlog = () => {
    // Navigate to the "/createBlog" page
    history.push("/createBlog");
  };

  return (
    <div className="Profileblog-cont">
      {isLoading ? (
        <LoadingSpinner /> // Render the loading spinner if data is still loading
      ) : (
        <React.Fragment>
          {!selectedBlogId && (
            <div className="Profileslider1-buttons">
              <div className="ProfilesearchButton">
                <SearchComponent onSearch={handleSearch} />
              </div>
            </div>
          )}

          <Route path="/blogs/:blogId">
            <div className="ProfileselectblogM">
              <BlogDetails
                userId={userId}
                userName={userName}
                scrollPosition={scrollPosition}
              />
            </div>
          </Route>

          <Route path="/">
            {isLoading ? (
              <LoadingSpinner /> // Loading spinner dikhaao jab data loading hai
            ) : (
              <div className="Profileblog-container">
                {filteredBlogData.length > 0 ? (
                  <div className="Profileblog-slider1">
                    {filteredBlogData.slice(0, blogsToShow).map((post) => (
                      // Render the blog list as before
                      <div
                        className="ProfileMainBlogCard"
                        key={post._id}
                        onClick={() => handleBlogClick(post._id)}
                      >
                        {/* <div className="ProfileBloglike-comment-info">
                    <span>
                      <LikeButton blogId={post._id} userId={userId} />
                    </span>
                  </div> */}
                        <div
                          className="Profileblog-card"
                          data-blog-id={post._id}
                          onClick={() => handleBlogClick(post._id)}
                          onKeyPress={() => handleBlogClick(post._id)}
                          role="button"
                          tabIndex={0}
                        >
                          <h2>{post.title}</h2>
                          <span>{post.topic}</span>
                        </div>
                        <div className="ProfileblogAuthorDate">
                          <div className="ProfileBlogauthor">
                            <a class="Blogauthor-avatar" href="#">
                              <span>
                                <img src={post.photo || defaultPhotoUrl} alt="User" className="BlogProfilePhoto" />
                              </span>
                            </a>
                            <svg class="half-circle" viewBox="0 0 106 57">
                              <path d="M102 4c0 27.1-21.9 49-49 49S4 31.1 4 4"></path>
                            </svg>
                            <div class="Blogauthor-name">
                              <div class="Blogauthor-name-prefix">Author</div>
                              <p className="Profileauthor-container">
                                {post.author}
                              </p>
                            </div>
                          </div>
                          <div className="ProfileBlogdate-container">
                            <p>{formatDate(post.date)}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  // If no blogs are found, display a message
                  <div className="Profileno-blogs-message">
                    <p>No blogs found.</p>
                    {userName ? (
                      <p>
                        To write a blog, Create blog button will be available in
                        the sidebar in Blogs.
                      </p>
                    ) : (
                      <p>
                        Please <a href="/login">login</a> to write a blog.
                        Create blog button will be available in the sidebar
                        after login in Blogs.
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          </Route>

          {/* {showLoginModal && (
        <LoginModal
          onLogin={handleLogin}
          onClose={() => setShowLoginModal(false)}
          blogId={selectedBlogId}
        />
      )} */}
        </React.Fragment>
      )}
    </div>
  );
}

Blog2.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default Blog2;
