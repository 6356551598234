// Import necessary dependencies
import React from "react";
import { useHistory } from "react-router-dom";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarked,
  faMapMarker,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

// Footer component
const Footer = () => {
  const history = useHistory();

  // Portfolio mappings
  const portfolioMappings = {
    "Personal Portfolio": "Portfolio1",
    "Retail WebSites": "Portfolio2",
    "Restaurant WebSites": "Portfolio3",
  };

  // Handle category click
  const handleCategoryClick = (category) => {
    const identifier = portfolioMappings[category];

    if (identifier) {
      history.push(`/products/${identifier}`);
    } else {
      console.error(`Identifier not found for category: ${category}`);
    }
  };

  // Get the current year
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-columns">
          <div className="footer-logo">
            <a href="/">
              <img src="/images/logo.PNG" alt="Logo" />
            </a>
          </div>
        </div>
        <div className="footer-columns">
          <h2>Links</h2>
          <ul>
            {[
              "Personal Portfolio",
              "Retail WebSites",
              "Restaurant WebSites",
            ].map((category) => (
              <li key={category}>
                <a onClick={() => handleCategoryClick(category)}>{category}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-columns">
          <h2>Contact Us</h2>
          <ul>
            <li>
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <a href="mailto:customercare@ownsit.xyz">
                customercare@ownsit.xyz
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faPhone} />{" "}
              <a href="tel:+916376399906">6376399906</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faMapMarker} />{" "}
              <a href="#">Balaji Vihar 2, Vatika Road, Jaipur (302019)</a>
            </li>
          </ul>
        </div>
        <div className="footer-social">
          <div className="social-icons">
            <a href="#" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
      </div>

      <hr className="footer-line" />
      <p className="footer-text">
        Copyright &copy; {currentYear} OwnsIt.xyz, &nbsp; All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
