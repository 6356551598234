import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "./SearchComponent.css";

const SearchComponent = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isInputVisible, setIsInputVisible] = useState(false);
  const searchContainerRef = useRef(null);

  const handleSearch = ({ target: { value } }) => {
    setSearchTerm(value);
    onSearch(value);
  };

  const handleIconClick = () => {
    setIsInputVisible((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target)
    ) {
      setIsInputVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="ProfileBlogsearch-container" ref={searchContainerRef}>
      <div className="ProfileBlogsearch-input-container">
        <input
          className="ProfileBlogsearch-input"
          type="text"
          placeholder="Search blogs"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      {/* <div className="ProfileBlogsearchIcon">
        <FontAwesomeIcon icon={faSearch} onClick={handleIconClick} />
      </div> */}
    </div>
  );
};

SearchComponent.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchComponent;
