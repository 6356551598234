import React, { useState } from "react";

const ProfileDetails = ({
  name,
  email,
  id,
  about,
  editMode,
  onAboutChange,
  onIdChange,
}) => {
  const [charCount, setCharCount] = useState(about?.length);

  const handleIdChange = (e) => {
    onIdChange(e.target.value);
  };

  const handleAboutChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 200) {
      onAboutChange(newValue);
      setCharCount(newValue.length);
    }
  };

  return (
    <div className="Userprofile-details">
      <p>
        <span className="user-id">
          {editMode ? (
            <input
              type="text"
              className="UseridText"
              value={id}
              onChange={handleIdChange}
            />
          ) : (
            id
          )}
        </span>
      </p>
      <h2>{name}</h2>
      <p className="userEmail">{email}</p>
      <p className="userAbout">
        {editMode ? (
          <>
            <textarea
              value={about}
              className="UseraboutText"
              onChange={handleAboutChange}
            />
            <span className="char-count">{charCount}/200</span>
          </>
        ) : (
          about?.split("\n").map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))
        )}
      </p>
    </div>
  );
};

export default ProfileDetails;
