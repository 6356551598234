import React, { useState, useEffect } from "react";
import "../Css/Navbar.css";
import { useDispatch } from "react-redux";
import { actions } from "../Redux/searchSlice";
import Search from "../Searching/Search";
import SearchResultsPage from "../Searching/MainSearchCombinePage/SearchResultsPage";
import { Link, useHistory } from "react-router-dom";
import { NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faBell,
  faCircleUser,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import defaultPhotoUrl from "../../assets/images/User.png";
import { profilePicBytoken } from "../SearchApi";

const Navbar = () => {
  const [searchResults, setSearchResults] = useState(null);
  const [userName, setUserName] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  const [photo, setPhoto] = useState(false)

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleSearch = (results) => {
    setSearchResults(results);
    dispatch(actions.setSearchResults(results));
    history("/search-results", { searchResults: results });
  };

  const portfolioMappings = {
    "Restaurant WebSites": "Portfolio3",
    "Personal Portfolio": "Portfolio1",
    "Retail WebSites": "Portfolio2",
  };

  const handleCategoryClick = (category) => {
    const identifier = portfolioMappings[category];

    if (identifier) {
      history.push(`/products/${identifier}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setShowMenu(false);
    } else {
      console.error(`Identifier not found for category: ${category}`);
    }
  };

  const [isActive, setIsActive] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!"token");

  useEffect(() => {
    // Check if the user is logged in
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("name");
    setIsLoggedIn(!!token);
    setUserName(name || "");
  }, []);

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  const removeActive = () => {
    setIsActive(false);
  };

  const handleLogout = () => {
    // Clear localStorage and update login status
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("eamil");
    setIsLoggedIn(false);
    // Redirect to the logout page if needed
    history.push("/logout");
  };

  const handleSubmenuClick = () => {
    setShowMenu(false); // Collapse the Navbar when a submenu link is clicked
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
  };

  useEffect(() => {
    if (!showMenu) {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
    }
  }, [showMenu]);

  const hideNavbarRoutes = [
    "/blogs",
    "/login",
    "/signup",
    "/search-results",
    "/dashboard",
    "/reset-pwd",
  ];

  const shouldShowNavbar = !hideNavbarRoutes.includes(location.pathname);

  useEffect(() => {
    profilePicBytoken(setPhoto);
  }, []);

  return (
    shouldShowNavbar && (
      <nav className={`MainNav ${showMenu ? "show-menu" : ""}`}>
        <div className="navbar-container">
          <a href="/" className="logoLink">
            <div className="logo">
              <img src="/images/logo.PNG"></img>
              <a id="owns">OwnsIt</a>
            </div>
          </a>

          {/* <div className="menu-icon" onClick={toggleActiveClass}>
          <div className={`bar ${isActive ? "animate" : ""}`}></div>
          <div className={`bar ${isActive ? "animate" : ""}`}></div>
          <div className={`bar ${isActive ? "animate" : ""}`}></div>
        </div> */}

          <div className={`menu ${showMenu ? "show" : ""}`}>
            <div className="middle-menu">
              <div className="menu-item">
                <a href="#">Services</a>

                <div className="submenu">
                  {Object.keys(portfolioMappings).map((category) => (
                    <a
                      key={category}
                      href={`/products/${portfolioMappings[category]}`}
                      onClick={handleSubmenuClick} // Collapse Navbar on submenu click
                    >
                      {category}
                    </a>
                  ))}
                </div>
              </div>

              {/* <div className="menu-item">
              <a href="#">Resources</a>
              <div className="submenu">
                <a href="#">Resource 1</a>
                <a href="#">Resource 2</a>
                <a href="#">Resource 3</a>
              </div>
            </div> */}
              <div className="menu-item">
                <a href="/blogs">Blogs</a>
              </div>
              <div className="menu-item">
                <a href="/contactUs">Contact</a>
              </div>
            </div>

            <div className="right-menu">
              {/* <div>
                <Search onSearch={handleSearch} />
              </div> */}

              <div className="loginNav">
                {isLoggedIn ? (
                  <div className="loginNavDiv">
                    {/* <div className="left-symbols">
                      <div className="notification-symbol">
                        {" "}
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                    </div> */}
                    <div className="menu-item">
                      <a>
                        <div className="logout-symbol">
                          <img src={photo || defaultPhotoUrl} alt="User" className="BlogProfilePhoto" />
                        </div>
                      </a>
                      <div className="submenu">
                        {/* <a href="/dashboard">Dashboard</a> */}
                        {/* <a href="#">Resource 3</a> */}
                        <a
                          href="/"
                          className="logoutSymbolMain"
                          onClick={handleLogout}
                        >
                          <FontAwesomeIcon icon={faSignOutAlt} />
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <a href="/login">Login</a>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="hamburger" onClick={toggleMenu}>
            <div className={`bar ${showMenu ? "animate" : ""}`}></div>
            <div className={`bar ${showMenu ? "animate" : ""}`}></div>
            <div className={`bar ${showMenu ? "animate" : ""}`}></div>
          </div>
        </div>
        {searchResults && <SearchResultsPage results={searchResults} />}
      </nav>
    )
  );
};

export default Navbar;
