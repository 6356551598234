import { Redirect, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import Welcome from "../pages/Welcome/Welcome";
import AdminEmails from "../pages/AdminEmails/AdminEmails";
import GlobalProps from "../pages/GlobalProps/GlobalProps";
import JWT from "../pages/JWT/JWT";
import ScheduleTask from "../pages/ScheduleTask/ScheduleTask";
import SideNavs from "../pages/SideNavs/SideNavs";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import { Container } from "reactstrap";
import LandingPage from "../Components/LandingPage";
import LoadingSpinner from "../Components/LoadingSpinner";
import Login from "../pages/Login/Login";
import Workspace from "../Components/Workspace/Workspace";
import DashboardMain from "../Dashboard/DashboardMain";

const getWrappedComponent = (Comp, isLoading, hideSideNav = false) => {
  return (
    <div className="wrapper">
      {isLoading ? (
        <div className="loading">
          <LoadingSpinner />
        </div>
      ) : null}
      {!hideSideNav ? (
        <div className="inner-wrapper">
          <main>
            <div className="pageWrapper d-lg-flex">
              {/* <aside className="sidebarArea shadow1" id="sidebarArea">
                <Sidebar />
              </aside> */}
              {/* <DashboardMain /> */}
              <div className="contentArea">
                {/* <Header />
                <Container className="p-4 main-content" fluid>
                  <Comp />
                </Container> */}
              </div>
            </div>
          </main>
        </div>
      ) : (
        <div className="inner-wrapper">
          <Comp />
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

const LoggedInRoutes = ({ isLoading }) => {
  return (
    <div>
      {/* <Route exact path="/dashboard">
        {getWrappedComponent(DashboardMain, isLoading)}
      </Route> */}
      <Route exact path="/workspace">
        {getWrappedComponent(Workspace, isLoading)}
      </Route>
      <Route exact path="/admin-emails">
        {getWrappedComponent(AdminEmails, isLoading)}
      </Route>
      <Route exact path="/global-props">
        {getWrappedComponent(GlobalProps, isLoading)}
      </Route>
      <Route exact path="/jwt">
        {getWrappedComponent(JWT, isLoading)}
      </Route>
      <Route exact path="/scheduled-tasks">
        {getWrappedComponent(ScheduleTask, isLoading)}
      </Route>
      <Route exact path="/side-navs">
        {getWrappedComponent(SideNavs, isLoading)}
      </Route>
      <Route exact path="/">
        {getWrappedComponent(LandingPage, isLoading, true)}
      </Route>
      {/* <Route exact path="/login">
        {getWrappedComponent(Login, isLoading)}
      </Route> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
  };
};

export default connect(mapStateToProps, {})(LoggedInRoutes);
