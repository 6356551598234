import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Editor from "./Editor";
import "./Blog CSS/CreateBlog.css";
import { createBlog } from "../SearchApi";

const CreateBlog = () => {
  const [blogTitle, setBlogTitle] = useState("");
  const [userName, setUserName] = useState("");
  const [blogTopic, setBlogTopic] = useState("");
  const [editorValue, setEditorValue] = useState("");
  const [blogSubmitted, setBlogSubmitted] = useState(false);
  const [editMode, setEditMode] = useState(true); // Set editMode to true by default

  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("name");
    setUserName(name || "");
  }, []);

  const handleEditorChange = (value) => {
    setEditorValue(value);
  };

  const handleBackToBlogs = () => {
    history.push("/blogs");
  };

  const handleBlogSubmit = () => {
    const blogData = {
      title: blogTitle,
      author: userName,
      content: editorValue,
      date: new Date().toUTCString(),
      topic: blogTopic,
    };

    // console.log("Submitting blog data:", blogData); // Log to check if blog data is correct

    createBlog(blogData)
      .then((response) => {
        //console.log("Server response:", data);
        setBlogSubmitted(true);
        setBlogTitle("");
        setBlogTopic("");
        setEditorValue("");
        setEditMode(true);
        history.push("/blogs");
      })
      .catch((error) => {
        console.error("Error sending data to the server:", error);
      });
  };

  return (
    <div className="create-blog-container">
      <div>
        <h3 className="create-blog-containerH3">Write Your Blog</h3>
        <div className="create-blog-containerMDiv">
          <>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          </>
          <div className="create-blog-containerDiv">
            <label htmlFor="Title"> Title </label>
            <input
              type="text"
              placeholder="Enter Blog Title"
              value={blogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
            />
          </div>

          <>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          </>
          <div className="create-blog-containerDiv">
            <label htmlFor="Subject"> Subject </label>
            <input
              type="text"
              placeholder="What's your blog about? (up to 100 words)"
              value={blogTopic}
              onChange={(e) => setBlogTopic(e.target.value)}
            />
          </div>
        </div>

        <Editor
          value={editorValue}
          onChange={handleEditorChange}
          editMode={editMode}
        />
        <div className="create-blog-containerButton">
          <div className="create-blog-containerButtonBack">
            <button type="button" onClick={handleBackToBlogs}>
              Back to Blogs
            </button>
          </div>
          <div className="create-blog-containerButtonSubmit">
            <button type="button" onClick={handleBlogSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
      {blogSubmitted && <p>Blog submitted successfully!</p>}
    </div>
  );
};

CreateBlog.propTypes = {
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default CreateBlog;
