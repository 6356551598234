import interceptor from "./interceptor.js";

const api = async (url, options) => {
  try {
    if (!options) {
      options = {};
    }
    const modifiedOptions = await interceptor.request(options);
    const response = await fetch(url, modifiedOptions);

    //console.log('response ', response);

    if (response.ok) {
      try {
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("application/json")) {
          // Parse JSON using .json() method
          const responseData = await response.json();
          return responseData;
        } else {
          // If content-type is not JSON, return response text
          return { nonJsonResponse: true, responseText: await response.text() };
        }
      } catch (error) {
        console.error("Error parsing response:", error);
        throw new Error("Error parsing response");
      }
    } else {
      // Check if response is unauthorized (status code 401 or 403) or token expired
      if (response.status === 401 || response.status === 403) {
        // Remove token, name, and email from local storage
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("email");

        // Set redirect URL in cookie

        if (window.location.hostname === "localhost") {
          document.cookie = `redirectUrl=${encodeURIComponent(
            window.location.href
          )}; expires=Thu, 01 Jan 2030 00:00:00 UTC; path=/`;
        } else {
          document.cookie = `redirectUrl=${encodeURIComponent(
            window.location.href
          )}; expires=Thu, 01 Jan 2030 00:00:00 UTC; Domain=ownsit.xyz; Secure; SameSite=Lax; path=/`;
        }

        // Redirect user to login page
        window.location.href = "/login";
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export default api;
