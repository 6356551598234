import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './Blog CSS/SearchComponent.css';

const SearchComponent = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isInputVisible, setIsInputVisible] = useState(false);
  const searchContainerRef = useRef(null);

  const handleSearch = ({ target: { value } }) => {
    setSearchTerm(value);
    onSearch(value);
  };

  const handleIconClick = () => {
    setIsInputVisible((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
      setIsInputVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="Blogsearch-container" ref={searchContainerRef}>
      <div className="Blogsearch-input-container">
        <input
          className={`Blogsearch-input ${isInputVisible ? 'visible' : ''}`}
          type="text"
          placeholder="Search blogs"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <div className="BlogsearchIcon">
        <FontAwesomeIcon
          icon={faSearch}
          onClick={handleIconClick}
        />
      </div>
    </div>
  );
};

SearchComponent.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchComponent;
