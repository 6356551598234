// PortfolioDetails.js
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import PortfolioNavbar from "./PortfolioNavbar";
import api from "../../api";
import AnimatedBackground from "./PortfolioList/AnimatedBackground";
import TemplateItem from "./TemplateItem";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function PortfolioDetails() {
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [allTemplates, setAllTemplates] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const { portfolioId } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        const portfolioResponse = await fetch(
          `${API_BASE_URL}/api/portfolio/${portfolioId}`
        );
        const portfolioData = await portfolioResponse.json();

        // Assume portfolioData has a structure like { id, templates, tags }
        setSelectedPortfolio({
          id: portfolioData.id,
          templates: portfolioData.templates,
          tags: portfolioData.tags,
        });
      } catch (error) {
        console.error(
          `Error fetching data for portfolio ${portfolioId}`,
          error
        );
      }
    };

    fetchPortfolioData();
  }, [portfolioId]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [portfolioId]);

  useEffect(() => {
    const fetchAllTemplates = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/templates`);
        const data = await response.json();
        setAllTemplates(data);
      } catch (error) {
        console.error("Error fetching all templates", error);
      }
    };

    fetchAllTemplates();
  }, []);

  const handleDemoClick = async (templateId) => {
    try {
      // Fetch the demo link for the selected template from the server
      const demoLinkResponse = await fetch(
        `${API_BASE_URL}/api/demo/${templateId}`
      );
      const demoLinkData = await demoLinkResponse.json();

      // Check if the demo link is available
      if (demoLinkData && demoLinkData.demo) {
        // Open a new window with the demo link
        window.open(demoLinkData.demo, "_blank");
      } else {
        console.error("Demo link not available for template:", templateId);
      }
    } catch (error) {
      console.error("Error fetching demo link data", error);
    }
  };

  const handleTryClick = async (
    templateId,
    templateName,
    templateDetails,
    templateComponents
  ) => {
    //console.log(templateComponents);
    try {
      const templateDetailsToSend = {
        templateId: templateId,
        name: templateName,
        details: templateDetails,
        formOrder: templateComponents,
      };

      const saveAndTryTemplateResponse = await api(
        `${API_BASE_URL}/api/saveAndTryTemplate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(templateDetailsToSend),
        }
      );

      const saveAndTryTemplateData = await saveAndTryTemplateResponse;

      //console.log(saveAndTryTemplateData.formOrder.templateId);

      if (saveAndTryTemplateData.success) {
        //console.log("Template details and form order updated successfully");
        // Redirect to the tryForm page
        history.push("/workspace");
      } else {
        console.error(
          "Error saving and trying template:",
          saveAndTryTemplateData.error || saveAndTryTemplateData.message
        );
      }
    } catch (error) {
      console.error("Error saving and trying template:", error);
    }
  };

  const handleWorkspaceClick = async () => {
    history.push("/workspace");
  };

  const handleCustomiseClick = async () => {
    history.push("/customise");
  };

  return (
    <div className="MainPortfolio">
      {/* <AnimatedBackground /> */}
      <div className="SelectedPortfolioCont">
        <div className="SelectedPortfolio">
          {/* Render the details of the selected portfolio
          <h2 className="SelectedPortfolioTags">Tags</h2>
          <ul className="SelectedPortfolioTag">
            {selectedPortfolio.tags &&
              selectedPortfolio.tags.map((tag, index) => (
                <li key={index}>{tag}</li>
              ))}
          </ul> */}

          <div className="selcetedPortfolioTCont">
            {/* <h2 className="SelectedPortfolioTamp">Templates</h2> */}
            <ul className="SelectedPortfolioTamplates">
              {/* Iterate over the templates and render each one */}
              {selectedPortfolio?.templates &&
                selectedPortfolio?.templates.map((templateId, index) => {
                  const template = allTemplates.find(
                    (t) => t._id === templateId
                  );
                  if (!template) {
                    console.error(`Template not found for id: ${templateId}`);
                    return null;
                  }
                  return (
                    <TemplateItem
                      key={index}
                      template={template}
                      handleDemoClick={handleDemoClick}
                      handleTryClick={handleTryClick}
                    />
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="buttonscustwork">
        <button
          className="WorkspaceCustomiseButton"
          onClick={handleCustomiseClick}
        >
          Customise Template
        </button>
        <button className="WorkspaceButton" onClick={handleWorkspaceClick}>
          Go to Workspace
        </button>
      </div> */}
    </div>
  );
}

export default PortfolioDetails;
