import React from "react";
import Feature1 from "../../assets/images/www.gif";
import Feature2 from "../../assets/images/Feature2.gif";
import star from "../../assets/images/star.svg";

const Features = () => {
  return (
    <div className="features-container">
      <h1>
        Why <bold> Ownsit.xyz </bold> is Your Ultimate Digital Ally
      </h1>

      <div className="feature-item">
        <div className="feature-content">
          <h2> Custom WebSite Design </h2>
          <div className="feature-contentIn">
            <p>
              <img src={star} alt="Star"></img> Responsive and Mobile-Friendly
            </p>
            <p>
              <img src={star} alt="Star"></img> Content Management
            </p>
            <p>
              <img src={star} alt="Star"></img> Analytics and Reporting
            </p>
          </div>
        </div>
        <div className="feature-image-container">
          <img src={Feature1} alt="Feature 1" className="feature-image" />
        </div>
      </div>

      <div className="feature-item alternate">
        <div className="feature-content">
          <h2> Website Management </h2>
          <div className="feature-contentIn">
            <p>
              <img src={star} alt="Star"></img> Updating and Maintain Your
              Website
            </p>
            <p>
              <img src={star} alt="Star"></img> Search Engine Optimization
            </p>
            <p>
              <img src={star} alt="Star"></img> Logo and Photo Design
            </p>
            <p>
              <img src={star} alt="Star"></img> E-Commerce Integration
            </p>
          </div>
        </div>
        <div className="feature-image-container">
          <img src={Feature2} alt="Feature 2" className="feature-image" />
        </div>
      </div>
    </div>
  );
};

export default Features;
