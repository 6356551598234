// SearchINSearch.jsx
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../Redux/searchSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { fetchSearchResults } from '../SearchApi';
import '../Css/SearchINSearch.css';

const SearchINSearch = ({ }) => {
  const dispatch = useDispatch();
  const { searchTerm, loading, activeButton } = useSelector((state) => state.searchResults);

  const handleInputChange = (e) => {
    dispatch(actions.setSearchTerm(e.target.value));
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    dispatch(actions.setSearchResults([]));
    dispatch(actions.setSearchLoading(true));
    try {
      
      const sortedResults = await fetchSearchResults ({ searchTerm, activeButton });

      dispatch(actions.setSearchLoading(false));

      dispatch(actions.setSearchResults(sortedResults));
    } catch (error) {
      console.error(error);
      dispatch(actions.setSearchLoading(false));
    } finally {
      dispatch(actions.setSearchLoading(false));
    }
  };

  return (
    <div>
      <form className="SearchINsearch-bar" onSubmit={handleSearch}>
        <div className="SearchINSearch-container">
          <input
            type="text"
            name="searchInput"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search People & Blogs..."
            className="SearchINSearch-input"
          />
          <button type="submit" className="SearchINSearch-btn">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchINSearch;
