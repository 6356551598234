import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions as searchResultsActions } from "../Redux/searchSlice";
import { useHistory } from "react-router-dom";
import api from "../../api";
import * as apiUrls from "../../apiUrls";
import PortfolioNavbar from "../Products/PortfolioNavbar";
import "./workspace.css";
import Domain from "./Domain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleXmark } from "@fortawesome/free-solid-svg-icons";

function LiveErrorMessage({ missingForms }) {
  if (missingForms && missingForms.length > 0) {
    return (
      <div className="LiveErrorMessage">
        <p>Missing forms: {missingForms.join(", ")}</p>
      </div>
    );
  }

  return null;
}

function Workspace() {
  const [templates, setTemplates] = useState([]);
  const [liveErrorMessages, setLiveErrorMessages] = useState({});
  const [missingForms, setMissingForms] = useState([]);
  const history = useHistory();

  const fetchTriedTemplates = async () => {
    try {
      const response = await api(apiUrls.triedTemplatesUrl);
      setTemplates(response);
    } catch (error) {
      console.error("Error fetching tried templates:", error);
    }
  };

  useEffect(() => {
    fetchTriedTemplates();
  }, []);

  const handleEditClick = (templateId) => {
    history.push(`/workspace/template/${templateId}`);
  };

  const handleDeleteClick = async (templateId) => {
    try {
      const deleteResponse = await api(apiUrls.deleteTemplateUrl(templateId), {
        method: "DELETE",
      });

      if (deleteResponse.ok) {
        // Update the templates state by removing the deleted template
        setTemplates((prevTemplates) =>
          prevTemplates.filter((template) => template._id !== templateId)
        );
        //console.log("Template deleted successfully");

        // Wait for a short delay before fetching tried templates again
        setTimeout(() => {
          fetchTriedTemplates();
        }, 500);
      } else {
        console.error("Failed to delete template");
      }
    } catch (error) {
      console.error("Error deleting template", error);
    }
  };

  const handleLiveClick = async (templateId) => {
    try {
      // Check if a domain exists for the templateId
      const response = await api(apiUrls.checkDomainUrl(templateId));
      const data = await response;

      if (data.exists) {
        // If domain exists, proceed with the current logic
        const liveResponse = await api(apiUrls.handleLiveClickUrl(templateId), {
          method: "POST",
        });

        //console.log(liveResponse);

        if (liveResponse) {
          const result = await liveResponse;
          //console.log(result);
          if (result.success) {
            // Redirect the user to the live route with the domain
            const domainResponse = await api(apiUrls.getDomainsUrl(templateId));
            const domainData = await domainResponse;

            if (domainData.length > 0) {
              const domainName = domainData[0].domainName;
              //history.push(`${domainName}`, "_blank");
              window.open(`/${domainName}`, "_blank");
            } else {
              console.error("Domain not found for template:", templateId);
            }
          } else {
            // Display error message to the user
            //console.log("Server response:", result);

            // Update this part to set the missing forms directly
            setMissingForms(result.missingForms || []);

            // Update this part to set the live error message
            setLiveErrorMessages((prevMessages) => ({
              ...prevMessages,
              [templateId]: result.missingForms ? (
                <LiveErrorMessage missingForms={result.missingForms} />
              ) : (
                result.message || "Unknown error"
              ),
            }));
          }
        } else {
          console.error("Failed to handle live click");
        }
      } else {
        // If domain doesn't exist, display a message
        // console.log(
        //   "Domain does not exist. Please Attach Domain before going live."
        // );
        const errorMessage =
          "Domain does not exist. Please Attach Domain before going live.";
        setLiveErrorMessages((prevMessages) => ({
          ...prevMessages,
          [templateId]: errorMessage,
        }));
        setTimeout(() => {
          setLiveErrorMessages((prevMessages) => {
            const { [templateId]: _, ...rest } = prevMessages;
            return rest;
          });
        }, 5000);
      }
    } catch (error) {
      console.error("Error handling live click:", error);
    }
  };

  const dispatch = useDispatch();

  const handleCustomizeClick = async (templateId) => {
    try {
      const response = await api(apiUrls.getFormOrderUrl(templateId));
      // if (!response.ok) {
      //     throw new Error(`Failed to fetch form order data. Status: ${response.status}, ${response.statusText}`);
      // }

      const result = await response;
      //console.log("Complete server response:", result);

      if (result.success) {
        const formOrder = result.formOrder || {}; // Ensure formOrder exists

        // Dispatch the setFormOrder action to update the Redux store
        dispatch(searchResultsActions.setFormOrder(formOrder));

        // Redirect the user to the customize route with the form order data
        history.push({
          pathname: "/customise",
          state: {
            formOrder,
            templateId,
          },
        });
      } else {
        console.error("Server returned unsuccessful response:", result.error);
      }
    } catch (error) {
      console.error("Error handling customize click:", error);
    }
  };

  return (
    <div className="WorkspaceContaniner">
      <PortfolioNavbar className="MainPortfolioNav" />
      <div className="Workspacei">
        <div className="Workspace">
          <h2 className="WorkspaceHader">Your Tried Templates</h2>
          <ul className="Workspaceul">
            {templates.map((template, index) => (
              <li
                key={template._id}
                className={`Workspaceli ${
                  index === templates.length - 1 ? "highlighted" : ""
                }`}
              >
                <div className="WorkspaceMainDivII">
                  <button
                    className="WorkspaceDelete"
                    onClick={() => handleDeleteClick(template._id)}
                  >
                    <FontAwesomeIcon icon={faFileCircleXmark} />
                  </button>
                  <div className="WorkspaceDivII">
                    <p className="Workspacename"> {template.name}</p>
                    <p className="WorkspaceDet"> {template.details}</p>
                    <div className="Workspacebtn">
                      <button
                        className="WorkspaceEdit"
                        onClick={() => handleEditClick(template._id)}
                      >
                        Edit
                      </button>
                      <button
                        className="WorkspaceCustomize"
                        onClick={() => handleCustomizeClick(template._id)}
                      >
                        Customise
                      </button>
                      <button
                        className="WorkspaceLive"
                        onClick={() => handleLiveClick(template._id)}
                      >
                        Live
                      </button>
                    </div>
                  </div>
                </div>
                {liveErrorMessages[template._id] && (
                  <div className="LiveErrorMessage">
                    {liveErrorMessages[template._id]}
                  </div>
                )}
                <div className="WorkspaceDivIII">
                  <Domain templateId={template._id} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Workspace;
