import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ProfileDetails from "./ProfileDetails";
import ProfilePhoto from "./ProfilePhoto";
import { useHistory } from "react-router-dom";
import { userProfileEditSave } from "../Components/SearchApi";

const ProfileHeader = ({ name, email, photo, id, about, isSameUser }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedAbout, setEditedAbout] = useState(about);
  const [editedId, setEditedId] = useState(id);
  const { profileId } = useParams();

  const history = useHistory();

  const handleEditSave = async () => {
    userProfileEditSave(
      profileId,
      editedAbout,
      editedId,
      editMode,
      setEditMode,
      history
    );
  };

  return (
    <div className="profile-container">
      <div>
        <div className="profile-header">
          <ProfilePhoto initialPhoto={photo} isSameUser={isSameUser} />
          <ProfileDetails
            name={name}
            email={email}
            id={editedId} // Pass the edited ID
            about={editedAbout} // Pass the edited About
            editMode={editMode}
            onAboutChange={(value) => setEditedAbout(value)}
            onIdChange={(value) => setEditedId(value)}
          />
          {isSameUser && (
            <button
              className={`setting-button ${
                editMode ? "save-mode" : "edit-mode"
              }`}
              onClick={handleEditSave}
            >
              {editMode ? "Save" : "Edit"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
