import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch
import DynamicComponentViewer from './DynamicComponentViewer';
import PortfolioNavbar from '../Products/PortfolioNavbar';
import './Customise.css';

const Customise = () => {
  // Use useSelector to access the formOrder from the Redux store
  const formOrder = useSelector((state) => state.searchResults.formOrder);
  const dispatch = useDispatch(); // Initialize useDispatch

  return (
    <div className='customiseContainer'>
      <PortfolioNavbar className="MainPortfolioNav" />
      {/* Pass formOrder and setFormOrder to DynamicComponentViewer */}
      <DynamicComponentViewer
        className='customiseContainerDynamic'
        formOrder={formOrder}
        setFormOrder={(newFormOrder) => dispatch({ type: 'UPDATE_FORM_ORDER', payload: newFormOrder })}
      />
    </div>
  );
};

export default Customise;
