// TemplateItem.js
import React from "react";

const TemplateItem = ({ template, handleDemoClick, handleTryClick }) => {
  if (!template?.image) {
    return null;
  }

  const imageSrc = template?.image
    ? require(`../../assets/${template.image}`)
    : null;

  return (
    <div className="SelectedPortfolioT event-card has-before hover:shine">
      <div class="card-banner img-holder">
        <img
          src={imageSrc}
          loading="lazy"
          alt="Flavour so good you’ll try to eat with your eyes."
          class="img-coverPortfolio"
        />
      </div>
      <div class="card-content">
        <h3 className="SelectedPortfolioTH">{template.name}</h3>
        <p className="SelectedPortfolioTD">
          {/* {template.details || "No details available"}
           */}
          Interested in template preview ? <br /> Just let us know
        </p>
        {/* Add other template details as needed */}
      </div>
      <div className="SelectPortBtn">
        {/* <button
          className="SelectedPortfolioDemo SelectedPortfolioDemo1"
          onClick={() => handleDemoClick(template.id)}
        >
          Preview
        </button> */}
        {/* <button
          className="SelectedPortfolioTry"
          onClick={() => handleTryClick(template)}
        >
          Try
        </button> */}
        {/* <button
                          className="SelectedPortfolioTry"
                          onClick={() =>
                            handleTryClick(
                              template.id,
                              template.name,
                              template.details,
                              template.components
                            )
                          }
                        >
                          Try
                        </button> */}
      </div>
    </div>
  );
};

export default TemplateItem;
